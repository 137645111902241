import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Table, Modal } from 'semantic-ui-react';

import { Input, SuccessToast, ErrorToast, Button, Text } from '../../components';
import ResultService from '../../api/result';

import './sendResultsModal.scss';

const SendResultsModal = ({ results, fetchResults, toggleModal, isModalToggled }) => {
    const [isSending, setIsSending] = useState(false);
    const [selectedResults, setSelectedResults] = useState([]);

    useEffect(() => {
        const rez = Object.keys(results).map(key => results[key]);
        setSelectedResults(rez)
    }, [results])

    const sendResults = async () => {
        let error = false;
        try {
            setIsSending(true);
            const formattedResults = selectedResults.map(res => {
              if(!res.patient.email || res.patient.email === '') error = true;
              return {id: res.id, email: res.patient.email}
            })
            if(error) {
                setIsSending(false);
                return toast.error(<ErrorToast message="All emails required" />);
            }
          await ResultService.sendResults(formattedResults);
          fetchResults({});
          toast.success(<SuccessToast message="Emails sent" />);
          setIsSending(false);
          toggleModal(false);
        }catch (error) {
          toast.error(<ErrorToast error={error} />);
          setIsSending(false);
        }
    }

    const onResultChange = (resId, value) => {
        const res = selectedResults.map(res => {
            if(res.id === resId) {
                res.patient.email = value;
            }
            return res;
        })
        setSelectedResults(res);
    }

  const renderTableData = () => {
    return selectedResults.map(result => {
      return (
        <Table.Row key={result.id}>
          <Table.Cell>
            <Text size="small">{result.patient.fullName}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{result.testProfile}</Text>
          </Table.Cell>
          <Table.Cell>
            <Input size="fullWidth" border value={result.patient.email} onChange={(e) => onResultChange(result.id, e.target.value)} />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Modal
      className="modal-full send-results"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size='large'
    >
      <Modal.Header>Send emails</Modal.Header>
      <Modal.Content>
      <div className="send-results__content">
          <Table className="send-results__table" striped>
              <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Result Profile</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
          </Table>
          <Button className="send-results__button" onClick={sendResults} isLoading={isSending} color="blue">Send</Button>
      </div>
      </Modal.Content>
    </Modal>
  );
};

export default SendResultsModal;
