import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Popup, Grid } from 'semantic-ui-react';

import {
  Button,
  Icon,
  Input,
  Text,
  Select,
  FileUpload,
  PhoneInput,
  ErrorToast,
  SuccessToast,
} from '../../components';

import countries from '../../helpers/countries';
import {uploadModes} from '../../constants';
import ClientService from '../../api/clients';
import UserService from '../../api/user';
import { s3Upload } from '../../utils/s3Upload';
import { setClientInformation } from '../../redux/generalState.actions';

import history from '../../history';

import './createClient.scss';

// TODO: split up components into smaller components

const ClientDetails = props => {
  const dispatch = useDispatch();
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );

  const colorPickerRef = useRef();
  const [client, setClient] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [clientName, setClientName] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [accountHolder, setAccountHolder] = useState('');
  const [holderEmail, setHolderEmail] = useState('');
  const [emailTemplate, setEmailTemplate] = useState('');
  const [type, setType] = useState('');
  const [role, setRole] = useState('');
  const [holderPhone, setHolderPhone] = useState('');
  const [issuer, setIssuer] = useState('');
  const [hciCode, setHciCode] = useState('');
  const [color, setColor] = useState('#3fbbe6');
  const [logoFile, setLogoFile] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    doSetClientState();

  }, []); // eslint-disable-line

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideColorPicker);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutsideColorPicker);
    };
  }, [colorPickerVisible]); // eslint-disable-line

  const doSetClientState = async () => {
    if (props.location?.state?.client) {
      setClientState(props.location.state.client);
    } else if (props.match.params.clientId) {
      const client = fetchClient(props.match.params.clientId);
      setClientState(client);
    }
  };

  const fetchClient = async (clientId) => {
    const { data } = await ClientService.getClient(clientId);
    return data;
  }

  // TODO: Fix this page, logic is totally random
  const setClientState = client => {
    setClient(client);
    setClientName(client.name);
    setCountry(client.country);
    setCity(client.city);
    setAddress(client.address);
    setZipCode(client.zip);
    setAccountHolder(client.accountHolderName);
    setHolderEmail(client.accountHolderEmail);
    setHolderPhone(client.accountHolderPhone);
    setIssuer(client.issuerName);
    setColor(client.color);
    setLogoPreview(client.logo);
    setEmailTemplate(client.emailTemplate);
    setType(client.type);
    setRole(client.role);
    setHciCode(client.hciCode);
    setAreaCode(client.areaCode);
  };

  const addLogo = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setLogoPreview(reader.result);
      setLogoFile(file);
    };

    reader.readAsDataURL(file);
  };

  const onColorChange = (color, e) => {
    setColor(color.hex);
  };

  const handleColorSelectComplete = color => {
    setColor(color.hex);
  };

  const handleClickOutsideColorPicker = event => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      if (colorPickerVisible) setColorPickerVisible(false);
    }
  };

  const checkForm = () => {
    let isError = false;
    const errors = {
      clientName: false,
      country: false,
      city: false,
      address: false,
      zipCode: false,
      accountHolder: false,
      holderPhone: false,
      holderEmail: false,
      logoFile: false,
    };

    if (!clientName) {
      errors.clientName = true;
      isError = true;
    }

    if (country.length === 0) {
      errors.country = true;
      isError = true;
    }

    if (city.length === 0) {
      errors.city = true;
      isError = true;
    }
    if (address.length === 0) {
      errors.address = true;
      isError = true;
    }
    if (zipCode.length === 0) {
      errors.zipCode = true;
      isError = true;
    }
    if (areaCode.length === 0) {
      errors.areaCode = true;
      isError = true;
    }
    if (accountHolder.length === 0) {
      errors.accountHolder = true;
      isError = true;
    }
    if (holderEmail.length === 0) {
      errors.holderEmail = true;
      isError = true;
    }
    if (holderPhone.length === 0) {
      errors.holderPhone = true;
      isError = true;
    }
    if ((props.subClient && client.type !== 'LAB') && hciCode.length === 0) {
      errors.hciCode = true;
      isError = true;
    }
    if ((props.subClient && client.type !== 'LAB') && color.length === 0) {
      errors.color = true;
      isError = true;
    }
    // if ((props.subClient && client.type !== 'LAB') && !logoPreview) {
    //   errors.logoFile = true;
    //   isError = true;
    // }

    if (isError) {
      toast.error(
        <ErrorToast message="Please fill out all required fields" />,
        {
          hideProgressBar: true,
          autoClose: 4000,
          pauseOnHover: true,
        }
      );
    }
    console.log("errors", errors)
    setErrors(errors);
    return isError;
  };

  const getHeaderText = () => {
    if (props.match.params.clientId) return 'Edit client';
    if (props.subClient) return 'Create clinic';
    return 'Create client';
  };

  const updateClient = (clientId, payload) => {
    return ClientService.updateClient(clientId, payload);
  };
 
  const createClient = async payload => {
    return ClientService.createClient(payload);
  };

  const formatPayload = logoPath => { 
    return {
      name: clientName,
      color,
      logo: logoPath ? logoPath.response : logoPath,
      accountHolderName: accountHolder,
      accountHolderEmail: holderEmail,
      accountHolderPhone: holderPhone,
      country: country,
      areaCode,
      city: city,
      zip: zipCode,
      address: address,
      type: props.subClient ? 'CLINIC' : 'LAB',
      hciCode
    };
  };

  const inviteUser = async (name, email, clientId) => {
    try {
      await UserService.createUser({
        name,
        email,
        clientId,
        role: 'admin',
      });
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const handleSubmit = async () => {
    const hasError = checkForm();
    if (hasError) return;

    setIsSaving(true);
    try {
      const logoResponse =
        (props.subClient || client.type !== 'LAB') || (logoPreview && !logoFile) ? logoPreview : await uploadLogo();

      const payload = formatPayload(logoResponse);

      if (props.match.params.clientId) {
        payload.type = client.type;
        const {data} = await updateClient(props.match.params.clientId, payload)
        if(localStorage.getItem('ROOT_ID') === client.id) dispatch(setClientInformation(data));
        return history.push('/settings');
      } else {
        await createClient(payload);
        // await inviteUser(payload.accountHolderName, payload.accountHolderEmail, data.id)
        toast.success(<SuccessToast message="Client created" />, {
          hideProgressBar: true,
          autoClose: 4000,
          pauseOnHover: true,
        });
        return history.push('/settings');
      }
    } catch (error) {
      console.log("error", error)
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  const uploadLogo = async () => {
    const resp = await s3Upload.uploadFileToS3(
      uploadModes.clientLogo,
      logoFile
    );
    return resp.location;
  };

  return (
    <div className="create-client">
      <div className="create-client__header">
        <Button
          icon
          size="xsmall"
          onClick={() => history.goBack()}
          color="blue"
          className="mr-3"
          backgroundColor='blue'
        >
          <Icon name="arrowLeft" color="white" fontSize="1.3em" />
        </Button>
        <Text bold size="bigger">{getHeaderText()}</Text>
      </div>
      <div className="create-client__content">
        <div className="create-client__content__form">
        {props.subClient && (
          <div className="create-client__content__info">
            <Text bold>You will not be able to edit the information after creation. However the Clinic will be able to. An invitation will be sent to the account holder, they will then be able to access their account.</Text>
          </div>
        )}
         
          <div className="create-client__content__form__first-row">
            <Input
              border
              className="create-client__content__form__input"
              label="Client name"
              placeholder="Enter client name"
              size="fullWidth"
              value={clientName}
              onChange={e => setClientName(e.target.value)}
              invalid={errors.clientName}
            />
          </div>
          
            <div className="create-client__content__form__grid">
            <Grid stackable>
            <Grid.Row>
            <Grid.Column className="create-client-form-columns">
            <Select
              border
              className="create-client__content__form__input"
              label="Select country"
              placeholder="Pick from the list"
              search
              size="fullWidth"
              value={country}
              invalid={errors.country}
              onChange={(e, { value }) => setCountry(value)}
              options={countries}
            />
            </Grid.Column>
            <Grid.Column className="create-client-form-columns"> 
            <Input
              border
              className="create-client__content__form__input"
              label="City"
              placeholder="Enter client city"
              size="fullWidth"
              value={city}
              invalid={errors.city}
              onChange={e => setCity(e.target.value)}
            />
             </Grid.Column>
            <Grid.Column className="create-client-form-columns">
            <Input
              border
              className="create-client__content__form__input"
              label="Address"
              placeholder="Enter client address"
              size="fullWidth"
              value={address}
              invalid={errors.address}
              onChange={e => setAddress(e.target.value)}
            />
            </Grid.Column>
            <Grid.Column className="create-client-form-columns">
            <Input
              border
              className="create-client__content__form__input"
              label="Zip Code"
              placeholder="Enter client zip code"
              size="fullWidth"
              value={zipCode}
              invalid={errors.zipCode}
              onChange={e => setZipCode(e.target.value)}
            />
            </Grid.Column>
           </Grid.Row>
           <Grid.Row>
           <Grid.Column className="create-client-form-columns">
            <Input
              border
              className="create-client__content__form__input"
              label="Area Code"
              placeholder="Enter client area code"
              size="fullWidth"
              value={areaCode}
              invalid={errors.areaCode}
              onChange={e => setAreaCode(e.target.value)}
            />
            </Grid.Column>
            <Grid.Column className="create-client-form-columns">
            <Input
              border
              className="create-client__content__form__input"
              label="Account holder"
              placeholder="Enter account holder name"
              size="fullWidth"
              value={accountHolder}
              invalid={errors.accountHolder}
              onChange={e => setAccountHolder(e.target.value)}
            />
            </Grid.Column>
            <Grid.Column className="create-client-form-columns">
            <Input
              border
              className="create-client__content__form__input"
              label="Account email"
              placeholder="Enter account holder email"
              size="fullWidth"
              value={holderEmail}
              invalid={errors.holderEmail}
              onChange={e => setHolderEmail(e.target.value)}
            />
            </Grid.Column>
            <Grid.Column className="create-client-form-columns">
            <PhoneInput
              border
              className="create-client__content__form__input"
              label="Account phone"
              placeholder="Enter account holder mobile #"
              size="fullWidth"
              value={holderPhone}
              invalid={errors.holderPhone}
              onChange={value => setHolderPhone(value)}
            />
             </Grid.Column>
            </Grid.Row>
           <Grid.Row>
            {props.subClient && (
              <Grid.Column className="create-client-form-columns">
                <Input
                  border
                  size="fullWidth"
                  className="create-client__content__form__input"
                  label="HCI code"
                  placeholder="Enter clinic HCI code"
                  value={hciCode}
                  invalid={errors.hciCode}
                  onChange={e => setHciCode(e.target.value)}
                />
              </Grid.Column>
            )}
            {(!props.subClient && client.type === 'LAB') && (
              <Grid.Column className="create-client-form-columns">
                <Input
                  size="fullWidth"
                  border
                  className="create-client__content__form__input"
                  label="Primary color (hex code)"
                  placeholder="Enter account holder email"
                  value={color}
                  onChange={e => onColorChange(e.target.value)}
                  invalid={errors.color}
                  onClick={() => setColorPickerVisible(true)}
                />
              </Grid.Column>
            )}
            </Grid.Row>
            </Grid >
          </div>
          {(!props.subClient && client.type === 'LAB') && (
            <div className="create-client__content__form__branding">
              <div className="create-client__content__form__branding__inputs">
                {colorPickerVisible && (
                  <div
                    className="create-client__content__form__branding__color-picker"
                    ref={colorPickerRef}
                  >
                    <ChromePicker
                      onChangeComplete={handleColorSelectComplete}
                      onChange={onColorChange}
                      color={color}
                    />
                  </div>
                )}
                <div
                  className={
                    colorPickerVisible
                      ? ' create-client__content__form__branding__file-upload create-client__content__form__branding__file-upload--no-pointer'
                      : 'create-client__content__form__branding__file-upload'
                  }
                >
                  {logoPreview ? (
                    <img
                      alt="logo"
                      src={logoPreview}
                      className="create-client__content__form__branding__file-upload__image"
                    />
                  ) : (
                    <div className="create-client__content__form__branding__file-upload__image-placeholder" />
                  )}
                  <FileUpload
                    onSelect={addLogo}
                    label={logoPreview ? 'Change logo' : 'Upload logo'}
                    accept="image/x-png,image/jpeg"
                    invalid={errors.logoFile}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="create-client__content__form__save-wrapper">
            <Button
              isLoading={isSaving}
              size="medium"
              onClick={handleSubmit}
              color="blue"
            >
              {getHeaderText()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClientDetails);
