import axios from './axiosInstance';

const methods = {
  async deleteTest(testId) {
    return axios.delete(`/test/${testId}`);
  },

  async createTest({name, email, role, clientId}) {
    return axios.post('/test', {name, email, role, clientId});
  },

  async updateTest(payload) {
    return axios.put('/test', payload);
  },

  async getTests(filters) {
    return axios.get('/test', { params: filters });
  },
}

export default methods;
