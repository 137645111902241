import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.546 1h-.819v2.455a.818.818 0 1 1-1.636 0V1h-3.273v2.455a.818.818 0 1 1-1.636 0V1H7.909v2.455a.818.818 0 1 1-1.636 0V1h-.818A2.455 2.455 0 0 0 3 3.455v16.363a2.455 2.455 0 0 0 2.455 2.455h13.09A2.455 2.455 0 0 0 21 19.818V3.455A2.455 2.455 0 0 0 18.546 1ZM12 16.546H7.09a.818.818 0 1 1 0-1.637H12a.818.818 0 1 1 0 1.636Zm4.91-3.273H7.09a.818.818 0 1 1 0-1.637h9.82a.818.818 0 1 1 0 1.637Zm0-3.273H7.09a.818.818 0 1 1 0-1.636h9.82a.818.818 0 1 1 0 1.636Z"
      fill={props.color}
    />
  </svg>
)

export default SvgComponent
