import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import ClientDetails from '../settings/components/clientDetails/ClientDetails';
import ClientUsers from '../settings/components/clientUsers/ClientUsers';

import {
  Icon,
  Button,
  Text,
  ErrorToast,
} from '../../components';
import history from '../../history';
import ClientService from '../../api/clients';
import UserService from '../../api/user';

import './clientPage.scss'

const ClientPage = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);

    const [users, setUsers] = useState([]);
    const [client, setClient] = useState(null);

  useEffect(() => {
    fetchInitialData();
  }, []); // eslint-disable-line

  const fetchInitialData = async () => {
    await Promise.all([fetchClient(), fetchClientUsers()]);
  };

  const fetchClient = async () => {
    try {
      const clientId = props.match.params.id;
      setIsLoading(true);
      const { data } = await ClientService.getClient(
        clientId
      );
      setClient(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchClientUsers = async () => {
    try {
      const users = [];
      const clientId = props.match.params.id;
      const { data } = await UserService.getUsers({ clientId });
      data.results.forEach(user => {
        if(user.role !== 'labApi') users.push(user)
      })
      setUsers(users);
      setLoadingUsers(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingUsers(false);
    }
  };

  return (
    <div className="client-page">
      <div className="client-page__header">
        <Button
          icon
          size="xsmall"
          onClick={() => history.goBack()}
          color="blue"
          className="mr-3"
          backgroundColor='blue'
        >
          <Icon name="arrowLeft" color="white" fontSize="1.3em" />
        </Button>
        <Text bold size="bigger">Clinic Details</Text>
      </div>
      <div className="client-page__details client-page__details--tight">
          <div className="client-page__details-wrapper">
              {(!isLoading && client) && <ClientDetails data={client} />}
          </div>
      </div>
      <div className="client-page__details">
          <div className="client-page__details-wrapper">
              {(!isLoading) && <ClientUsers fetchClientUsers={fetchClientUsers} users={users} />}
          </div>
      </div>
    </div>
  );
};

export default withRouter(ClientPage);
