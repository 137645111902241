import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Sidebar, Menu } from 'semantic-ui-react'

import { Icon, SideBarMenu, Text } from '../../components';
import history from '../../history';

import PatientsIcon from '../../assets/new/patients';
import ResultsIcon from '../../assets/new/results';
import SamplesIcon from '../../assets/new/samples';
import SettingsIcon from '../../assets/new/settings';

import './sideBar.scss';

const SideBar = props => {
  const sidebarRef = useRef();
  // const sidebarToggleButtonRef = useRef();
  const subMenuRef = useRef();

  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/results');
  const [subMenuItems, setSubMenuItems] = useState(false);

  const userInfo = useSelector(state => state.general.user);
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  const stateOfSideBar = useSelector(state=>state.changeBarState);
  
  // const toggleSidebar = e => {
  //   setIsSidebarToggled(!isSidebarToggled);
  // };
  
  useEffect(() => {
    const getSelectedTab = () => {
      const splitPath = history.location.pathname.split('/');
      if (splitPath[1] === 'results') return '/results';
      if (splitPath[1] === 'insights') return '/insights';
      if (splitPath[1] === 'calendar') return '/calendar';
      if (splitPath[1] === 'certificates') return '/certificates';
      if (splitPath[1] === 'course') return '/course/planned';
    };
    setSelectedTab(getSelectedTab());

    const handleClick = e => {
      if (
        sidebarRef.current.contains(e.target) ||
        // sidebarToggleButtonRef.current.contains(e.target) ||
        (subMenuRef.current && subMenuRef.current.contains(e.target))
      ) {
        return;
      }
      setIsSidebarToggled(false);
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  const navigationItems = [
    {
      title: 'Results Inbox',
      icon: ResultsIcon,
      urlKey: '/results',
    },
    {
      title: 'Patient & History',
      icon: PatientsIcon,
      urlKey: '/patients',
    },
    {
      title: 'Sample Collection',
      icon: SamplesIcon,
      urlKey: '/sample-collection',
    },
    {
      title: 'Settings',
      icon: SettingsIcon,
      urlKey: '/settings',
      role: { admin: true }
    },
  ];

  const containerClassNames = classNames('side-bar__container', {
    'side-bar__container--open': isSidebarToggled,
    'side-bar__container--closed': !isSidebarToggled,
  });

  const selectMenuItem = item => {
    item.subMenu && setSubMenuItems(item.subMenu);
    !item.subMenu && setSubMenuItems([]);

    if (typeof item.redirect !== 'undefined') return;
    props.history.push(item.urlKey);
    setSelectedTab(item.urlKey);
  };

  const splitPath = history.location.pathname.split('/');

  // TODO: move this to useEffect
  const renderItems = () => {
    let items = [];

    navigationItems.forEach((item, index) => {
      if (
        (!item.role || item.role[userInfo.role]) &&
        (!item.clientRole || item.clientRole[clientInformation.role]) &&
        (!item.type || item.type === clientInformation.type)
      )
        items.push(
          <div
            className={
              `/${splitPath[1]}` === item.urlKey
                ? 'side-bar__item side-bar__item--selected'
                : 'side-bar__item'
            }
            key={item.urlKey}
            onClick={() => selectMenuItem(item)}
          >
            <div className="side-bar__item__icon-wrapper">
              <item.icon color={`/${splitPath[1]}` === item.urlKey ? '#fff' : '#6e6e6e'}/>
              <Text className="side-bar__item__title" bold color={`/${splitPath[1]}` === item.urlKey ? "white": "lightGrey"}>
                {item.title}
              </Text>
            </div>
          </div>
        );
    });

    return items;
  };

  return (
   
    <Sidebar.Pushable as='section'>
      <Sidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        vertical
        visible={stateOfSideBar.sidebarShow}
        width='thin'
      >
    <div className="side-bar">
      <div className={containerClassNames} ref={sidebarRef}>
        <div className="side-bar__content">
          {renderItems()}
        </div>
      </div>
      {subMenuItems.length > 0 && (
        <div className="side-bar__sub-menu">
          <SideBarMenu
            clientType={clientInformation.type}
            role={userInfo.role}
            subMenuRef={subMenuRef}
            sidebarRef={sidebarRef}
            history={props.history}
            items={subMenuItems}
            closeMenu={() => setSubMenuItems([])}
            location={history.location}
            setIsSidebarToggled={setIsSidebarToggled}
          />
        </div>
      )}
    </div>
    </Sidebar>
    </Sidebar.Pushable>
  
  );
};

export default withRouter(SideBar);
