import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { unionBy } from 'lodash';

import PageWrapper from './pages/pageWrapper';
import Login from './pages/login';
import LoaderPage from './pages/loaderPage';
import Settings from './pages/settings';
import Patients from './pages/patients';
import SampleCollection from './pages/sampleCollection';
import PatientDetails from './pages/patientDetails';
import ResultDetails from './pages/resultDetails';
import CreateClient from './pages/createClient/CreateClient';
import ClientPage from './pages/clientPage/ClientPage';
import ClientGroupDetails from './pages/clientGroupDetails/ClientGroupDetails';

import { signOutUser, refreshUserToken } from './auth/authentication';
import ClientService from './api/clients';
import ClientGroupsService from './api/clientGroups';
import UserService from './api/user';
import { setClientInformation, setUser, setClientBranding, setUserClients,setClientClinics } from './redux/generalState.actions';
import { subdomains } from './constants';

import history from './history';

// *********************************
// DASHBOARD PAGES
// *********************************

import Dashboard from './pages/dashboard';

export const MainRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.general.user);

  const [isLoading, setIsLoading] = useState(true);

  // Todo add a check to see if user is authenticated
  useEffect(() => {
    getInitialData();
  }, []); //eslint-disable-line
  
  useEffect(() => {
    fetchAllClientGroups(userInfo.clientGroups)
  }, [userInfo]); //eslint-disable-line

  useEffect(() => {
    window.Intercom('update');
  }, [location]);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'a1z5uy25',
      name: userInfo.name,
      email: userInfo.email,
      user_id: userInfo.id,
      alignment: 'right',
      horizontal_padding: 0,
      vertical_padding: 0
    });
  }, [userInfo]);

  const getInitialData = async () => {
    const clientId = subdomains[window.location.hostname.split('.')[0]]
    localStorage.setItem('ROOT_ID', clientId);

    await checkIfAuthenticated();
    // await Promise.all([checkIfAuthenticated(), fetchClientDetails(), fetchUserDetails()]);
    setIsLoading(false);
  };

  const checkIfAuthenticated = async () => {
    const authToken = JSON.parse(localStorage.getItem('DP_AUTH_TOKEN'))
    const refreshToken = JSON.parse(localStorage.getItem('DP_REFRESH_TOKEN'))
    const path = history.location.pathname;

    if(!authToken) {
      await fetchClientBranding()
      return false;
    }

    if(moment.utc(authToken.expires).isAfter(moment())) {
      if(path === '/' && !(path === '/user-activate' || path === '/password-reset')) history.push('/results');
      await Promise.all([fetchUserDetails(), fetchClientBranding()]);
    } else if(refreshToken && moment.utc(refreshToken.expires).isAfter(moment())) {
      if(path !== '/user-activate' && path !== '/password-reset') await refreshUserToken(path, refreshToken.token)
      await Promise.all([fetchUserDetails(), fetchClientBranding()]);
    }
  }

  const fetchClientBranding = async () => {
    const clientId = subdomains[window.location.hostname.split('.')[0]]
    localStorage.setItem('ROOT_ID', clientId);
    const clientTheme = localStorage.getItem(`DP_CLIENT_THEME_${clientId}`);
    if(clientTheme) {
      const theme = JSON.parse(clientTheme)
      return dispatch(setClientBranding({ ...theme }));
    }
  
    const { data } = await ClientService.getClientBranding(clientId);
    console.log("data", data)
    localStorage.setItem(`DP_CLIENT_THEME_${clientId}`, JSON.stringify({ ...data }));
    dispatch(setClientBranding({ ...data }));
  };

  const fetchClientDetails = async (clientId) => {
    try {
      const { data } = await ClientService.getClient(clientId);
      dispatch(setClientInformation(data));
    } catch (error) {
      console.log('fetchClientDetails', error);
    }
  };

  const fetchAllClientGroups = async (clientGroups) => {
    try {
      if(!clientGroups || clientGroups.lenght === 0) return;
      const groupPromises = clientGroups.map(group => (
        fetchClientGroup(group.id)
      ))

      const groupClients = await Promise.all(groupPromises);

      let finalArray = [];
      groupClients.forEach(group => {
        finalArray = unionBy(finalArray, group, 'id')
      })

      dispatch(setUserClients(finalArray));
    } catch(error) {
      console.log("error", error);
    }
  }

  const fetchClientGroup = async (groupId) => {
    try {
      const {data} = await ClientGroupsService.getClientGroup(groupId);
      return data.clients;
    } catch(error) {
      console.log("error", error);
      return [];
    }
  }

 
  const fetchUserDetails = async () => {
    try {
      const clientId = subdomains[window.location.hostname.split('.')[0]]
      localStorage.setItem('ROOT_ID', clientId);
      const userId = localStorage.getItem('DP_USER_ID');
      if (!userId) return;
      const {data} = await UserService.getUser(userId);
      fetchClientClinics(data.labId)
      fetchAllClientGroups(data.clientGroups)
      window.intercomSettings = {
        name: data.name,
        email: data.email,
        user_id: data.id,
      };
      dispatch(setUser(data));
      await fetchClientDetails(data.clientId);
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const fetchClientClinics= async (labId)=>{
    try {
      const {data}= await ClientService.getSubClients(labId)
      dispatch(setClientClinics(data));
    } catch (error) {
      console.log("error", error);
    }
  }


  const ProtectedRoute = ({ component: Component, ...props }) => {
    if (!localStorage.getItem('DP_AUTH_TOKEN')) {
      signOutUser(false);
      return <Redirect to="/" />
    }

    return <Route {...props} render={props => <Component {...props} />} />;
  };

  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Suspense fallback={<LoaderPage />}>
          <Switch>
            <Route
              path="/password-reset"
              exact
              component={() => <Login />}
            />
            <Route
              path="/activate"
              exact
              component={() => <Login />}
            />
            <Route
              path="/user-activate"
              exact
              component={() => <Login />}
            />
            <Route exact path="/" component={() => <Login />} />
            <PageWrapper>
              <Switch>
                <ProtectedRoute
                  path="/results"
                  exact
                  component={() => <Dashboard />}
                />
                <ProtectedRoute
                  path="/patients"
                  exact
                  component={() => <Patients />}
                />
                <ProtectedRoute
                  path="/sample-collection"
                  exact
                  component={() => <SampleCollection />}
                />
                <ProtectedRoute
                  path="/patients/:patientId"
                  exact
                  component={() => <PatientDetails />}
                />
                <ProtectedRoute
                  path="/results/:resultId"
                  exact
                  component={() => <ResultDetails />}
                />
                <ProtectedRoute
                  path="/settings"
                  exact
                  component={() => <Settings />}
                />
                <ProtectedRoute
                  path="/client/:clientId"
                  exact
                  component={() => <CreateClient />}
                />
                <ProtectedRoute
                  path="/client-details/:id"
                  exact
                  component={() => <ClientPage />}
                />
                <ProtectedRoute
                  path="/client-group/:id"
                  exact
                  component={() => <ClientGroupDetails />}
                />
                <ProtectedRoute
                  path="/client-group"
                  exact
                  component={() => <ClientGroupDetails />}
                />
                <ProtectedRoute
                  path="/sub-client"
                  exact
                  component={() => <CreateClient subClient={true} />}
                />
                <ProtectedRoute
                  path="/sub-client/:clientId"
                  exact
                  component={() => <CreateClient subClient={true} />}
                />
                <Redirect to="/" />
              </Switch>
            </PageWrapper>
            <Redirect to="/" />
          </Switch>
        </Suspense>
      )}
    </>
  );
};
