import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'

import Results from '../results';

const Dashboard = () => {

  return (
    <div className="dashboard">
      <Results />
    </div>
  )
}

export default withRouter(Dashboard)