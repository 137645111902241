import axios from 'axios';
import moment from 'moment';

import {API_V1_ENDPOINT} from '../constants';
import { signOutUser, refreshUserToken } from '../auth/authentication';

const axiosInstance = axios.create({
  baseURL: API_V1_ENDPOINT
})

const requestHandler = request => {
    if(localStorage.getItem('DP_AUTH_TOKEN') && JSON.parse(localStorage.getItem('DP_AUTH_TOKEN')).token)
        request.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('DP_AUTH_TOKEN')).token}`;
    return request;
};

const responseHandler = response => {
    if (response?.status === 401) {
        signOutUser(true);
    }

    return response;
};

const errorHandler = error => {
    const localRefreshToken = JSON.parse(localStorage.getItem('DP_REFRESH_TOKEN'))

    if ((!localRefreshToken || (localRefreshToken && moment.utc(localRefreshToken.expires).isBefore(moment()))) && error?.response?.status === 401 && error.response.config.url.substring(0, 5) !== '/auth') {
        return signOutUser(true);
    }

    if(localRefreshToken && localRefreshToken && moment.utc(localRefreshToken.expires).isAfter(moment()) && error?.response?.status === 401 && error.response.config.url.substring(0, 5) !== '/auth') {
        return refreshUserToken();
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );


export default axiosInstance;
