import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab,  } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { ErrorToast } from '../../components';
import ClientDetails from './components/clientDetails/ClientDetails';
import ClientUsers from './components/clientUsers/ClientUsers';
import Header from './components/header/Header';
import ClientBranding from './components/clientBranding/ClientBranding';
import SubClients from './components/subClients/SubClients';
import ClinicGroups from './components/clinicGroups/ClinicGroups';

import { setClientInformation } from '../../redux/generalState.actions';
import ClientService from '../../api/clients';
import UserService from '../../api/user';

import './settings.scss';

const Settings = props => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [subClients, setSubClients] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const clientInfo = useSelector(state => state.general.clientInformation);

  useEffect(() => {
    fetchInitialData();
  }, []); // eslint-disable-line

  const fetchInitialData = async () => {
    await Promise.all([fetchClient(), fetchClientUsers(), fetchSubClients()]);
  };

  const fetchClient = async () => {
    try {
      const clientId = props.match.params.id;
      if(!clientId) return setIsLoading(false);
      setIsLoading(true);
      const { data } = await ClientService.getClient(
        clientId
      );
      dispatch(setClientInformation(data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchClientUsers = async () => {
    try {
      const users = [];
      const clientId = props.match.params.id || clientInfo.id;
      const { data } = await UserService.getUsers({ clientId });
      data.results.forEach(user => {
        if(user.role !== 'labApi') users.push(user)
      })
      setUsers(users);
      setLoadingUsers(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingUsers(false);
    }
  };

  const fetchSubClients = async () => {
    try {
      const clientId = props.match.params.id || clientInfo.id;
      const { data } = await ClientService.getSubClients(clientId);
      setSubClients(data);
      setLoadingUsers(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingUsers(false);
    }
  };

  const getPanes = () => {
    const panes = [
      {
        menuItem: 'Account',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <>
              <ClientDetails data={clientInfo} />
              {clientInfo.type === 'LAB' && (
                <ClientBranding
                  domain={clientInfo.subdomain}
                  brandColor={clientInfo.color}
                  logo={clientInfo.logo}
                />
              )}
            </>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Users',
        render: () => (
          <Tab.Pane loading={isLoading || loadingUsers}>
            <ClientUsers
              users={users}
              fetchClientUsers={fetchClientUsers}
            />
          </Tab.Pane>
        ),
      },
    ];

    if(clientInfo.type === 'LAB') {
      const clinicsPane = {
        menuItem: 'Clinics',
        render: () => (
          <Tab.Pane loading={isLoading || loadingUsers}>
            <SubClients subClients={subClients} fetchSubClients={fetchSubClients} />
          </Tab.Pane>
        ),
      }
      const clinicGroupsPane = {
        menuItem: 'Clinic Groups',
        render: () => (
          <Tab.Pane loading={isLoading || loadingUsers}>
            <ClinicGroups subClients={subClients} fetchSubClients={fetchSubClients} />
          </Tab.Pane>
        ),
      }
      panes.splice(1, 0, clinicsPane, clinicGroupsPane)
    }
    return panes;
  };

  return (
    <div className="client-details">
      <div className="client-details__content">
        {clientInfo.name && (
          <Header
            name={clientInfo.name}
            created={moment.unix(clientInfo.createdAt).format('DD/MM/YYYY')}
          />
        )}
        <div className="client-details__content__tab">
          <Tab panes={getPanes()} menu={{ secondary: true }} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Settings);
