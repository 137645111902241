import axios from './axiosInstance';

const methods = {
  async onSignInUser(data) {
    return axios.post('/auth/login', data);
  },
 
  async refreshToken(data) {
    return axios.post('/auth/refresh-tokens', data);
  },

  async verifyEmail(token) {
    return axios.post('/auth/verify-email', {token});
  },
 
  async registerUser(token) {
    return axios.post('/auth/register', {token});
  },

  async activateUser(token, password) {
    return axios.post(`/auth/activate-user?token=${token}`, {password});
  },

  async onForgotPassword(email, labId) {
    return axios.post('/auth/forgot-password', {email, labId});
  },

  async resetPassword({token, password}) {
    return axios.post(`/auth/reset-password?token=${token}`, {password});
  },

  async logOff(refreshToken) {
    return axios.post('/auth/logout', {refreshToken});
  },
};

export default methods;
