import React, { useState } from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import moment from 'moment';

import history from '../../../../history';
import ClientService from '../../../../api/clients';
import { Text, Button } from '../../../../components';

import './subClients.scss';

const SubClients = ({ fetchSubClients, subClients }) => {
  const [isDeleting, setIsDeleting] = useState('');

  const deleteClient = async clientId => {
    setIsDeleting(clientId);
    await ClientService.deleteClient(clientId);
    setIsDeleting('');
    fetchSubClients();
  };

  const renderTableData = () => {
    return subClients.map(client => {
      return (
        <Table.Row style={{ cursor: 'pointer' }} className="table-row" onClick={() => history.push(`/client-details/${client.id}`)}>
          <Table.Cell>
            <div className="sub-clients__table__user-abbreviation">
              <Text>{client.name.charAt(0)}</Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{client.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{client.hciCode}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{client.accountHolderEmail}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{moment(client.createdAt).format('DD/MM/YYYY')}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">
              {client.isDeleted ? (
                <div className="client-users__user-status client-users__user-status--red">
                  <Text size="tinniest" color="white">
                    De-activated
                  </Text>
                </div>
              ) : (
                <div className="client-users__user-status client-users__user-status--green">
                  <Text size="tinniest" color="white">
                    Active
                  </Text>
                </div>
              )
            }</Text>
          </Table.Cell>
          <Table.Cell className="table-row__cell--center">
            <Dropdown
              className="user-management__more-dropdown"
              icon="ellipsis vertical"
              floating
              labeled
              button
              direction="left"
              loading={isDeleting === client.id}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={client.isDeleted ? "Activate clinic" : "De-activate clinic"}
                  onClick={() => deleteClient(client.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="sub-clients">
      <Button
      size="medium"
      color="blue"
      onClick={() => history.push('/sub-client')}
      >
      Create clinic
      </Button>
      <div className="sub-clients__table-wrapper">
          <Table selectable unstackable>
              <Table.Header>
              <Table.Row>
                  <Table.HeaderCell style={{ width: '80px' }} />
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>HCI code</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '80px' }} />
              </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
          </Table>
      </div>
    </div>
  );
};

export default SubClients;
