import React from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Text } from '../../../../components';
import history from '../../../../history';

import './clientDetails.scss';

const ClientInfo = props => {
    return (
        <div className="settings">
            <div className="settings__edit-button">
            <Button
                color="blue"
                size="fluid"
                inverted
                onClick={() =>
                history.push({
                    pathname: `/client/${props.data.id}`,
                    state: { client: props.data },
                })
                }
            >
                Edit
            </Button>
            </div>
            <div className="settings__content">
              
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Name
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.name}</Text>
                    </div>
                </div>
                
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Account holder
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.accountHolderName}</Text>
                    </div>
                </div>
               
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Account holder email
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.accountHolderEmail}</Text>
                    </div>
                </div>
                
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Account holder mobile
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.accountHolderPhone}</Text>
                    </div>
                </div>
                
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Address
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.address}</Text>
                    </div>
                </div>
                               
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Area Code
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.areaCode}</Text>
                    </div>
                </div>
               
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Zip code
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.zip}</Text>
                    </div>
                </div>
                
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        City
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.city}</Text>
                    </div>
                </div>
               
                <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                    <Text dark size="small">
                        Country
                    </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                    <Text>{props.data.country}</Text>
                    </div>
                </div>
                
                {props.data.hciCode && (
                   
                    <div className="settings__data-wrapper">
                    <div className="settings__data-wrapper__label">
                        <Text dark size="small">
                        HCI code
                        </Text>
                    </div>
                    <div className="settings__data-wrapper__data">
                        <Text>{props.data.hciCode}</Text>
                    </div>
                    </div>
                   
                )}                
              
            </div>
            
        </div>
    );
};

export default withRouter(ClientInfo);
