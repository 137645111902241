import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import store from './store';
import history from './history';

import { MainRoutes } from './routes';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import 'semantic-ui-css/semantic.min.css';
import 'react-phone-number-input/style.css';
// import 'react-image-crop/lib/ReactCrop.scss';

toast.configure();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MainRoutes />
    </Router>
  </Provider>,

  document.getElementById('root')
);
