export const SET_CLIENT_INFORMATION = 'GENERAL_STATE/SET_CLIENT_INFORMATION';
export const SET_USER = 'GENERAL_STATE/SET_USER';
export const SET_CLIENT_BRANDING = 'GENERAL_STATE/SET_CLIENT_BRANDING';
export const SET_USER_CLIENTS = 'GENERAL_STATE/SET_USER_CLIENTS';
export const SET_CLIENT_CLINICS = 'GENERAL_STATE/SET_CLIENT_CLINICS'
/**
 * @function setClientInformation
 * @description Set information of the logged in client
 */
export const setClientInformation = clientInfo => ({
  type: SET_CLIENT_INFORMATION,
  payload: clientInfo,
});

/**
 * @function setClientTheme
 * @description Set information of the logged in client
 */
export const setClientBranding = clientBranding => ({
  type: SET_CLIENT_BRANDING,
  payload: clientBranding,
});

/**
 * @function setUser
 * @description Set state of logged in user
 */
export const setUser = user => ({
  type: SET_USER,
  payload: user,
});

/**
 * @function setUserClients
 * @description Set state of logged in user
 */
export const setUserClients = clients => ({
  type: SET_USER_CLIENTS,
  payload: clients,
});


/**
 * @function setClientClinics
 * @description Set information of all lab clinics
 */

 export const setClientClinics= clinics=>({
   type:SET_CLIENT_CLINICS,
   payload:clinics
 })
