import axios from './axiosInstance';

const methods = {
  async deleteResult(resultId) {
    return axios.delete(`/result/${resultId}`);
  },

  async createResult({name, email, role, clientId}) {
    return axios.post('/result', {name, email, role, clientId});
  },

  async updateResult(payload) {
    return axios.put('/result', payload);
  },

  async getResults(filters) {
    return axios.get('/result', { params: filters });
  },

  async getResult(resultId) {
    return axios.get(`/result/${resultId}`);
  },

  async getSignedFile(resultId) {
    return axios.get(`/result/${resultId}/signed-file`);
  },

  async sendResults(results) {
    return axios.post('/result/send', { results });
  },
};

export default methods;
