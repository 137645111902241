import axios from './axiosInstance';

const api = {
  async getClientGroups(clientId) {
    return axios.get(`/client-group/${clientId}/group`);
  },
  
  async getClientGroup(labId) {
    return axios.get(`/client-group/${labId}`);
  },

  async getClientGroupUsers(groupId) {
    return axios.get(`/client-group/${groupId}/users`);
  },
  
  async getClientGroupActiveUsers(groupId) {
    return axios.get(`/client-group/${groupId}/active/users`);
  },
  
  async createClientGroup(payload) {
    return axios.post('/client-group', payload);
  },

  async updateClientGroup(groupId, payload) {
    return axios.put(`/client-group/${groupId}`, payload);
  },

  async deleteClientGroup(groupId) {
    return axios.delete(`/client-group/${groupId}`);
  },

}

export default api