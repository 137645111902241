import axios from './axiosInstance';

const api = {
  async createClient(payload) {
    return axios.post(`/client/create`, payload);
  },

  async updateClient(clientId, payload) {
    return axios.put(`/client/${clientId}`, payload);
  },

  async deleteClient(clientId) {
    return axios.delete(`/client/${clientId}`);
  },

  async getClient(clientId) {
    return axios.get(`/client/${clientId}`);
  },

  async getClientBranding(clientId) {
    return axios.get(`/client/${clientId}/branding`);
  },

  async getSubClients(clientId) {
    return axios.get(`/client/${clientId}/sub-client`);
  },

  async getAllClients() {
  },

  async inviteUser(user) {
  },
}

export default api