import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text } from '../../../../components';

import './clientDetailsHeader.scss';

const ClientDetailsHeader = props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  return (
    <div className="client-details-header">
      <div className="client-details-header__abbreviation" style={{ background: clientBranding.logo ? 'white' : clientBranding?.color }}>
        {clientBranding.logo ?
          <img
            src={clientBranding.logo}
            alt="logo"
            className="client-branding__data-wrapper__logo mt-0"
          /> : <Text color="white">{props.name.charAt(0)}</Text>}
      </div>
      <div className="client-details-header__content">
        <div className="client-details-header__content__name">
          <Text size="big" bold>{props.name}</Text>
        </div>
        <div className="client-details-header__content__date">
          <Text size="tiny" color="lightGrey">
            {moment(props.createdAt).format('DD/MM/YYYY')}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsHeader;
