import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Table, Icon as SIcon } from 'semantic-ui-react';
import { debounce } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import axios from 'axios';
import downloadFile from 'js-file-download';

import history from '../../history';
import { Text, Search, Button, Icon, DatePicker, ErrorToast, CheckBox } from '../../components';
import PatientService from '../../api/patient';
import ResultService from '../../api/result';
import SendResultsModal from '../../features/SendResultsModal/SendResultsModal';

import './patientDetails.scss';

const Patients = ({ location, match }) => {
  const [patientDetails, setPatientDetails] = useState({});
  const [results, setResults] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [fetchingPatient, setFetchingPatient] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedRows, setSelectedRows] = useState({});
  const [isDownloading, setIsDownloading] = useState('');
  const [isModalToggled, toggleModal] = useState(false);

  const handleSearch = useCallback(
    debounce(
      ({ search}) => fetchResults(search),
      500
    ),
    []
  );

  useEffect(() => {
    if(location.state?.patient) setPatientDetails(location.state.patient)
    else fetchPatient();
  }, []);
  
  useEffect(() => {
    fetchResults();
  }, [fromDate, toDate]);

  const onSearchChange = searchTxt => {
    setSearchValue(searchTxt);
    handleSearch({ search: searchTxt });
  };

  const fetchResults = async (searchTxt = searchValue) => {
    try {
      setSearching(true);
      const searchParams = { patientId: match.params.patientId }
      if(!!searchTxt) searchParams.search = searchTxt
      if(!!fromDate) searchParams.from = moment.utc(fromDate).add(1, 'day').startOf('day').toISOString()
      if(!!toDate) searchParams.to = moment.utc(toDate).add(1, 'day').endOf('day').toISOString()
      const {data} = await ResultService.getResults(searchParams)
      setResults(data.results);
      setSearching(false);
    } catch(error) {
      console.log("error", error);
    }
  }

  const fetchPatient = async () => {
    try {
      setFetchingPatient(true);
      const {data} = await PatientService.getPatient(match.params.patientId)
      setPatientDetails(data);
      setFetchingPatient(false);
    } catch(error) {
      console.log("error", error);
    }
  }

  const onRowClick  = (e, result) => {
    e.stopPropagation()

    history.push({
      pathname:`/results/${result.id}`,
      state: { result }
    })
  }

  const onRowSelect = (e, id, result) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else selectedRows[id] = result;

    setSelectedRows({ ...selectedRows });
  };

  const onFileDownload = async (e, result) => {
    setIsDownloading(result.id);
    e.stopPropagation();
    try {
      const { data } = await ResultService.getSignedFile(result.id);

      await axios({
        url: data,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        downloadFile(
          response.data,
          `${result.pdf[0].name}.pdf`
        );
      });
      setIsDownloading(null);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
      setIsDownloading(null);
    }
  };
  
  const renderTableData = () => {
    return results.map(result => {
      return (
        <Table.Row className="table-row" onClick={(e) => onRowClick(e, result)} key={result.id}>
          <Table.Cell  onClick={e => onRowSelect(e, result.id, result)}>
            <div>
              <CheckBox
                className="patient-details__checkbox"
                checked={!!selectedRows[result.id]}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{result.order.id}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{moment(result.order.date).format('DD/MM/YYYY')}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{result.testProfile}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{result.order.orderer}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{moment(result.createdAt).format('DD/MM/YYYY')}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" uppercase>{result.status}</Text>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <div
              className={classNames('results__status-dot', {
                'results__status-dot--red': !result.emailSent,
                'results__status-dot--green': result.emailSent,
              })}
            />
          </Table.Cell>
          <Table.Cell>
            <div style={{ cursor: "pointer", marginLeft: '13px' }} onClick={(e) => onFileDownload(e, result)}>
              <SIcon
                loading={isDownloading === result.id}
                color="grey"
                circular
                size="small"
                inverted
                name={
                  isDownloading === result.id
                    ? 'spinner'
                    : 'download'
                }
              />
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const toggleResultsModal = () => {
    toggleModal(true);
  }

  return (
    <div className="patient-details">
      <div className="patient-details__header">
        <Button
          icon
          size="xsmall"
          onClick={() => history.goBack()}
          color="blue"
          className="mr-3"
        >
            <Icon name="arrowLeft" color="white" fontSize="1.3em" />
        </Button>
        <Text bold size="bigger">Patient Overview</Text>
      </div>
          
      <div className="patient-details__details-wrapper">
        {fetchingPatient ?
          <div className="patient-details__details-wrapper__loader-wrapper">
            <Loader active className="patient-details__details-wrapper__loader-wrapper__loader" size="small" />
          </div>
        : (
          <>
            <div className="patient-details__details-wrapper__row">
            
                <div className="patient-details__detail">
                  <Text className="patient-details__detail__header" color="grey" size="small" dark>Name</Text>
                  <Text className="patient-details__detail__data">{patientDetails.fullName}</Text>
                </div>
          
                {patientDetails.ssn && (
                  <div className="patient-details__detail">
                    <Text className="patient-details__detail__header" color="grey" size="small" dark>NRIC/FIN</Text>
                    <Text className="patient-details__detail__data">{patientDetails.ssn}</Text>
                  </div>
                )}
         
         
                {patientDetails.passNo && (
                  <div className="patient-details__detail">
                    <Text className="patient-details__detail__header" color="grey" size="small" dark>Passport Number</Text>
                    <Text className="patient-details__detail__data">{patientDetails.passNo}</Text>
                  </div>
                )}
             
              
                <div className="patient-details__detail">
                  <Text className="patient-details__detail__header" color="grey" size="small" dark>Gender</Text>
                  <Text className="patient-details__detail__data" uppercase>{patientDetails.gender}</Text>
                </div>
             
                {patientDetails.dateOfBirth && (
                  
                  <div className="patient-details__detail">
                    <Text className="patient-details__detail__header" color="grey" size="small" dark>Date of Birth</Text>
                    <Text className="patient-details__detail__data">{moment(patientDetails.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                  </div>
                )}
             
              
                <div className="patient-details__detail">
                  <Text className="patient-details__detail__header" color="grey" size="small" dark>Nationality</Text>
                  <Text className="patient-details__detail__data">{patientDetails.nationality}</Text>
                </div>
              
              {patientDetails.email && (
                <div className="patient-details__detail">
                    <Text className="patient-details__detail__header" color="grey" size="small" dark>Email</Text>
                    <Text className="patient-details__detail__data">{patientDetails.email}</Text>
                </div>
              )}
             
             
              {patientDetails.phoneNumber && (                
                <div className="patient-details__detail">
                    <Text className="patient-details__detail__header" color="grey" size="small" dark>Phone Number</Text>
                    <Text className="patient-details__detail__data">{patientDetails.phoneNumber}</Text>
                </div>
              )}
            
             
            </div>
          </>
        )}
      </div>
     

      <div className="patient-details__table-wrapper">
        <Text bold size="bigger">Patient Results</Text>
        <div className="patient-details__search mt-4">
        <div className="patient-details__search__section">
          {/* <Button
            color="blue"
            disabled={Object.keys(selectedRows).length === 0}
            onClick={() => sendResults()}
            isLoading={isSending}
          >
            Send lab report
          </Button> */}
          <Button
            color="blue"
            disabled={Object.keys(selectedRows).length === 0}
            onClick={() => toggleResultsModal(true)}
          >
            Send lab report
          </Button> 
        </div>
        <div className="results__search__section">
          <div style={{ display: 'flex' }}>
            <div className="mr-3" style={{ position: 'relative' }}>
              <Text size="tinniest" color="lightGrey" className="results__search__date-tag">From</Text>
              <DatePicker
              className="lab-documents__date"
              onChange={date => setFromDate(date)}
              value={fromDate}
              maxDate={toDate}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <Text size="tinniest" color="lightGrey" className="results__search__date-tag">To</Text>
              <DatePicker
              className="lab-documents__date"
              onChange={date => setToDate(date)}
              value={toDate}
              minDate={fromDate}
              clearIcon={true}
              />
            </div>
          </div>
          <div id="patient-details-search">
            <Search
              isLoading={isSearching}
              size="large"
              dropdown={false}
              onSearchChange={(e, { value }) => onSearchChange(value)}
              isFilter={true}
            />
          </div>
        </div>
        </div>
        <div className="details-table">
        <Table selectable unstackable striped>
            <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} />
                  <Table.HeaderCell>Order no.</Table.HeaderCell>
                  <Table.HeaderCell>Order date</Table.HeaderCell>
                  <Table.HeaderCell>Test/Profile</Table.HeaderCell>
                  <Table.HeaderCell>Ordering doctor</Table.HeaderCell>
                  <Table.HeaderCell>Date reported</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Email sent</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderTableData()}</Table.Body>
        </Table>
        </div>
      </div>
      <SendResultsModal results={selectedRows} toggleModal={toggleModal} isModalToggled={isModalToggled} fetchResults={fetchResults} />
    </div>
  );
};

export default withRouter(Patients);
