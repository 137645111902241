import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { Icon as LocalIcon, Text } from '../index';

import './sideBarMenu.scss';

const SidebarMenu = props => {
  useEffect(() => {
    const handleClick = e => {
      if (
        props.subMenuRef.current.contains(e.target) ||
        props.sidebarRef.current.contains(e.target)
      ) {
        return;
      }
      props.closeMenu();
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }); // eslint-disable-line

  const onClick = item => {
    props.setIsSidebarToggled(false);
    props.closeMenu();
    props.history.push(item.path);
  };

  const renderItems = () => {
    const splitPath = props.location.pathname.split('/');
    let items = [];
    props.items.forEach(item => {
      if (
        (!item.role || item.role[props.role]) &&
        (!item.type || item.type === props.clientType)
      )
        items.push(
          <div
            className="side-bar-menu__content__item"
            onClick={() => onClick(item)}
            key={item.path}
          >
            {/* <div className="side-bar-menu__item__icon-wrapper">
              <Icon name={item.icon} alt={item.icon} height={29} width={29} />
            </div> */}
            <Text color="grey" lassName="ml-2">
              {item.label}
            </Text>
            {item.path.split('/')[1] === splitPath[1] &&
              item.path.split('/')[2] === splitPath[2] && (
                <div className="side-bar__item__highlight" />
              )}
          </div>
        );
    });

    return items;
  };

  return (
    <div className="side-bar-menu" ref={props.subMenuRef}>
      <div className="side-bar-menu__content">{renderItems()}</div>
    </div>
  );
};

SidebarMenu.propTypes = {
  items: PropTypes.array,
  header: PropTypes.string,
};

SidebarMenu.defaultProps = {
  items: [],
  header: 'Header',
};

export default SidebarMenu;
