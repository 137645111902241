import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import C from 'cleave.js/react';
import { useSelector } from 'react-redux';

import colors from '../styles/colors';
import { Text } from '../components';

const Cleave = props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  return (
    <Wrapper className={props.wrapperClassName}>
      {props.label && <Text type="label">{props.label}</Text>}
      <CleaveWrapper
        style={props.style}
        className={props.className}
        size={props.size}
        placeholder={props.placeholder}
        options={props.options}
        onFocus={props.onFocus}
        onChange={props.onChange}
        invalid={props.invalid}
        value={props.value}
        disabled={props.disabled}
        clientBranding={clientBranding}
      />
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage || 'This field is required'}
        </Text>
      )}
    </Wrapper>
  );
};

Cleave.propTypes = {
  size: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.object,
};

Cleave.defaultProps = {
  size: 'normal',
  onChange: () => {},
  placeholder: '',
  options: {},
};

const getSize = size => {
  if (size === 'tiny') return { width: '100px' };
  if (size === 'small') return { width: '150px' };
  if (size === 'normal') return { width: '300px' };
  if (size === 'large') return { width: '400px' };
  if (size === 'fullWidth') return { width: '100% !important' };
};

const getBorder = invalid => {
  if (invalid)
    return `border: solid 1px #ff5252; border-radius: 10px; padding: 10px; height: 45px;`;
  return `border: solid 1px ${colors.GREY}; border-radius: 10px; padding: 10px; height: 45px;`;
};

const getBorderHighlight = (clientColor) => {
  return `outline: none; border: solid 1px ${clientColor || colors.HIGHLIGHT_BLUE};`;
};

const CleaveWrapper = styled(C)`
  ${props => getBorder(props.invalid)}
  border-radius: 10px;
  height: 45px;
  padding-left: 10px;
  font-size: 17px !important;
  font-weight: 200 !important;
  ${props => getSize(props.size)}
  color: ${colors.DARK_GREY};
  margin: 0 !important;

  &:focus {
    outline: none;
    ${props => getBorderHighlight(props.clientBranding?.color)}
  }
  &:placeholder {
    color: ${colors.DARK_GREY};
    opacity: 0.6;
    transition: all 0.3s ease;
    font-weight: 200 !important;
    font-size: 16px !important;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export default Cleave;
