import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.624 2.653h-1.308C14.176 1.723 13.327 1 12.338 1a2.036 2.036 0 0 0-2.014 1.653H8.84a.313.313 0 0 0-.318.31V4.82c0 .173.141.31.318.31h6.82a.313.313 0 0 0 .317-.31V2.962c0-.172-.141-.31-.353-.31Zm-3.322.93a.662.662 0 0 1-.67-.655c0-.379.317-.654.67-.654.389 0 .672.31.672.654 0 .379-.283.654-.672.654Z"
      fill={props.color}
    />
    <path
      d="M19.122 3.686h-2.438v1.136c0 .55-.46 1.032-1.025 1.032H8.841a1.02 1.02 0 0 1-1.025-1.032V3.686H5.378C4.6 3.686 4 4.306 4 5.028v16.63C4 22.414 4.636 23 5.378 23h13.744c.777 0 1.378-.62 1.378-1.343V5.063c0-.758-.636-1.377-1.378-1.377ZM5.732 7.679c0-.516.459-.964.989-.964h4.345c.53 0 .99.448.99.964v4.476c0 .516-.46.964-.99.964H6.721c-.53 0-.99-.448-.99-.964V7.679ZM18.38 20.728H6.085a.35.35 0 0 1-.353-.345c0-.206.14-.344.353-.344H18.38c.212 0 .354.138.354.345 0 .206-.142.344-.354.344Zm0-2.273H6.085c-.212 0-.353-.137-.353-.344a.35.35 0 0 1 .353-.344H18.38a.35.35 0 0 1 .354.344c0 .207-.142.345-.354.345Zm0-2.237H6.085a.35.35 0 0 1-.353-.345c0-.206.14-.344.353-.344H18.38c.212 0 .354.138.354.344a.35.35 0 0 1-.354.345Zm0-3.89h-4.805c-.176 0-.353-.173-.353-.345a.35.35 0 0 1 .353-.344h4.805c.212 0 .354.137.354.344a.35.35 0 0 1-.354.344Zm0-2.17h-4.805c-.176 0-.353-.172-.353-.344s.177-.344.353-.344h4.805a.35.35 0 0 1 .354.344.35.35 0 0 1-.354.344Z"
      fill={props.color}
    />
    <path
      d="M8.876 11.156c-.672 0-1.237.551-1.237 1.205v.069h2.473v-.069c0-.654-.53-1.205-1.236-1.205Z"
      fill={props.color}
    />
    <path
      d="M11.067 7.404H6.72c-.141 0-.283.138-.283.276v4.475c0 .173.142.276.283.276h.212v-.07c0-1.032.883-1.893 1.943-1.893.53 0 1.025.207 1.378.55.354.345.566.827.566 1.344v.069h.212a.275.275 0 0 0 .282-.276V7.68c.036-.172-.106-.275-.247-.275Zm-2.19 2.858c-.707 0-1.273-.551-1.273-1.24 0-.688.566-1.24 1.272-1.24.707 0 1.272.552 1.272 1.24 0 .689-.565 1.24-1.272 1.24Z"
      fill={props.color}
    />
    <path
      d="M9.44 9.022c0 .304-.252.55-.564.55a.558.558 0 0 1-.566-.55c0-.305.253-.551.566-.551.312 0 .565.246.565.55Z"
      fill={props.color}
    />
  </svg>
)

export default SvgComponent
