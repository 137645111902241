import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Icon, Pagination } from 'semantic-ui-react';
import { debounce } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import axios from 'axios';
import downloadFile from 'js-file-download';

import { Text, Search, DatePicker, ErrorToast, CheckBox, Button,Select } from '../../components';
import ResultService from '../../api/result';
import history from '../../history'
import SendResultsModal from '../../features/SendResultsModal/SendResultsModal';

import './results.scss';


const Results = (props) => {
  const [selectedClient, setSelectedClient] = useState('all');
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const userClients = useSelector(state => state.general.userClients);
  const clinicList = useSelector(state => state.general.clientClinics);

  const [results, setResults] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState({});
  const [isDownloading, setIsDownloading] = useState('');
  const [isModalToggled, toggleModal] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({ totalPages: 1 });
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    formatClinicOptions();
  }, [userClients])
  
  useEffect(() => {
    if(firstRender) return setFirstRender(false);
    fetchResults({});
  }, [fromDate, toDate, selectedClient, selectedClinics]);
  
  useEffect(() => {
    fetchClients()
    setUrlValuesToState();
  }, []);

  useEffect(() => {
    if(clinicOptions.length > 0 && clientInformation.type !== 'LAB') setSelectedClinics([clientInformation.id])
  }, [clinicOptions]);
  
  const handleSearch = useCallback(
    debounce(
      ({ search, fromDate, toDate }) => fetchResults({ search, fDate: fromDate, tDate: toDate }),
      500
    ),
    [fromDate, toDate, activePage, selectedClient, selectedClinics]
  );

  const fetchClients = async() => {
    if (clientInformation.type !== 'LAB') return;
    
    const clinicNames = clinicList.map(clinics => (
      {
        text: clinics.name,
        key: clinics.id,
        value: clinics.id
      }
    ))

    clinicNames.unshift({
      text: 'All clinics',
      key: 'all',
      value: 'all'
    })
   
    setClients(clinicNames)
  }

  const setUrlValuesToState = () => {
    const params = new URLSearchParams(props.history.location.search);
    const search = params.get('search')
    const client = params.get('client')
    const clients = params.get('clients')
    const formattedClients = clients ? clients.split(',') : null;
    const page = params.get('page')
    const from = params.get('from') ? moment(params.get('from'), 'DD-MM-YYYY').toDate() : '';
    const to = params.get('to') ? moment(params.get('to'), 'DD-MM-YYYY').toDate() : '';
    setSearchValue(search);
    setFromDate(from);
    setActivePage(page || 1);
    setToDate(to);
    if(formattedClients && formattedClients.isArray && !!formattedClients[0]) setSelectedClinics(formattedClients)
    fetchResults({ search, client, fDate: from, tDate: to, clients: formattedClients });
  }

  const updateSearchParams = (search, client, fDate, tDate, page, clients) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?page=${page}&client=${client}&search=${search || ''}&from=${fDate ? moment(fDate).format('DD-MM-YYYY') : ''}&to=${tDate ? moment(tDate).format('DD-MM-YYYY') : ''}&clients=${clients}`
    // if page value is not null add to string1

    window.history.pushState({ path: newurl }, '', newurl);
  };

  const onSearchChange = search => {
    setSearchValue(search);
    handleSearch({ search });
  };

  const fetchResults = async ({ search = searchValue,client=selectedClient, fDate = fromDate, tDate = toDate, page = activePage, clients = selectedClinics }) => {
    try {
      setSearching(true);
      const searchParams = { page }
      if(!!search) searchParams.search = search;
      if(!!client) searchParams.client = client;
      if(!!fDate) searchParams.from = moment.utc(fDate).add(1, 'day').startOf('day').toISOString()
      if(!!tDate) searchParams.to = moment.utc(tDate).add(1, 'day').endOf('day').toISOString()
      
      let selectedClinicz = [...clients];
      if (clinicOptions.length > 0 && clientInformation.type !== 'LAB' && clients && clients.length === 0)
        selectedClinicz = clinicOptions.map((clinic) => clinic.value);
      if(clinicOptions.length > 0 && clientInformation.type !== 'LAB' && selectedClinicz) searchParams.clients = selectedClinicz
      const {data} = await ResultService.getResults(searchParams)
      setResults(data.results);
      delete data.results;
      setPaginationInfo(data);
      updateSearchParams(search,client, fDate, tDate, page, selectedClinicz);
      setSearching(false);
    } catch(error) {
      console.log("error", error)
    }
  }

  const onRowClick = (e, result) => {
    e.stopPropagation()
    history.push({
      pathname:`/results/${result.id || result._id}`,
      state: { result }
    })

    ResultService.updateResult({ order: { id: result.order.id } , isRead: true })
  }

  // const onPatientClick = (e, patientId) => {
  //   e.stopPropagation()
  //   history.push(`/patient/${patientId}`);
  // }

  const onRowSelect = (e, id, result) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else selectedRows[id] = result;

    setSelectedRows({ ...selectedRows });
  };

  const onFileDownload = async (e, result) => {
    setIsDownloading(result.id);
    e.stopPropagation();
    try {
      const { data } = await ResultService.getSignedFile(result.id);

      await axios({
        url: data,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        downloadFile(
          response.data,
          `${result.pdf[0].name}.pdf`
        );
      });
      setIsDownloading(null);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
      setIsDownloading(null);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
    fetchResults({ page: activePage })
  }
  const onSelectClient = ({ value }) => {      
    setSelectedClient(value);   
}

const formatClinicOptions = () => {
  if(!userClients || userClients.length === 0) return
  const clinicz = userClients.map(clinic => ({ text: clinic.name, value: clinic.id }))
  setClinicOptions(clinicz);
}

  const renderTableData = () => {
    return results.map(result => {
      return (
        <Table.Row className="table-row" onClick={(e) => onRowClick(e, result)} key={result.id}>
          <Table.Cell onClick={e => onRowSelect(e, result.id, result)}>
            <div>
              <CheckBox
                className="results__checkbox"
                checked={!!selectedRows[result.id]}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text dark={!result.isRead} size="tiny">{result.patient.fullName}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{result.patient.ssn || result.patient.passNo}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{moment(result.order.date).format('DD/MM/YYYY')}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{moment(result.reportedDate || result.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
          </Table.Cell>
          {clientInformation.type === 'LAB' && (
            <Table.Cell>
              <Text size="tiny">{result.clinic.name}</Text>
            </Table.Cell>
          )}
          <Table.Cell>
            <Text size="tiny">{result.order.id}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny">{result.testProfile}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny" uppercase>{result.status}</Text>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <div
              className={classNames('results__status-dot', {
                'results__status-dot--red': !result.emailSent,
                'results__status-dot--green': result.emailSent,
              })}
            />
          </Table.Cell>
          <Table.Cell>
            {result.pdf.length > 0 && (
              <div style={{ cursor: "pointer", marginLeft: "13px" }} onClick={(e) => onFileDownload(e, result)}>
                <Icon
                  font
                  size="small"
                  loading={isDownloading === result.id}
                  color="grey"
                  circular
                  inverted
                  name={
                    isDownloading === result.id
                      ? 'spinner'
                      : 'download'
                  }
                />
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="results">
      {/* <div className="results__header">
        <Text type="pageHeader">Results</Text>
      </div> */}
      <div className="results__search">
        <div className="results__search__section">
          <Button
            color="blue"
            disabled={Object.keys(selectedRows).length === 0}
            onClick={() => toggleModal(true)}
          >
            Send lab report
          </Button>
        </div>
        <div className="results__search__section">
          <div style={{ display: 'flex' }}>
          {clinicOptions?.length > 0 && clientInformation.type !== 'LAB' && (
            <div className="mr-3" style={{ position: 'relative' }}>
              <Text
                size="tinniest"
                color="lightGrey"
                className="results__search__date-tag"
              >
                Clinics
              </Text>
              <Select
                search
                multiple
                border
                icon={false}
                placeholder="All clinics"
                className="results__client-select"
                options={clinicOptions}
                value={selectedClinics}
                onChange={(e, { value }) => setSelectedClinics(value)}
              />
            </div>
            )}
            <div className="mr-3" style={{ position: 'relative' }}>
              <Text size="tinniest" color="lightGrey" className="results__search__date-tag">From</Text>
              <DatePicker
              className="lab-documents__date"
              onChange={date => setFromDate(date)}
              value={fromDate}
              maxDate={toDate}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <Text size="tinniest" color="lightGrey" className="results__search__date-tag">To</Text>
              <DatePicker
              className="lab-documents__date"
              onChange={date => setToDate(date)}
              value={toDate}
              minDate={fromDate}
              clearIcon={true}
              />
            </div>
          </div>
          <Search            
            isLoading={isSearching}
            size="large"
            value={searchValue}
            dropdown={false}
            onSearchChange={(e, { value }) => onSearchChange(value)}
            isFilter={true}
          />
         
        </div>
      </div>
      <div className="results__table-wrapper">
          <Table selectable unstackable striped>
              <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} />
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell>NRIC/FIN/Pass</Table.HeaderCell>
                <Table.HeaderCell>Ordered</Table.HeaderCell>
                <Table.HeaderCell>Reported</Table.HeaderCell>
                {clientInformation.type === 'LAB' && (
                   <Table.HeaderCell style={{ padding: '6px 10px' }}>
                      <Select
                      border
                      placeholder="Filter by clinic"
                      value={selectedClient}
                      onChange={(e,data)=>onSelectClient(data)}
                      search
                      selection
                      options={clients}
                      selectClassName='sample-collection__select-text'
                      />
                  </Table.HeaderCell>
                  // <Table.HeaderCell>Clinic</Table.HeaderCell>
                )}
                <Table.HeaderCell>Order No.</Table.HeaderCell>
                <Table.HeaderCell>Test/Profile</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '96px'}}>Email sent</Table.HeaderCell>
                <Table.HeaderCell>Report</Table.HeaderCell>
              </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
          </Table>
      </div>
      {!isSearching && paginationInfo.totalPages > 1 && paginationInfo.page &&  (
        <Pagination
          size="small"
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={paginationInfo.totalPages}
          firstItem={null}
          lastItem={null}
        />
      )}
      <SendResultsModal results={selectedRows} toggleModal={toggleModal} isModalToggled={isModalToggled} fetchResults={fetchResults} />
    </div>
    
  );
};

export default withRouter(Results);
