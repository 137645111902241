import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import history from '../../../../history';
import ClientGroupsService from '../../../../api/clientGroups';
import { Text, Button, ErrorToast, Input, Icon } from '../../../../components';

import './clinicGroups.scss';

const ClinicGroups = ({subClients}) => {
  const clientInformation = useSelector(
      state => state.general.clientInformation
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isModalToggled, toggleModal] = useState(false);
  const [name, setName] = useState('');
  const [isDeleting, setIsDeleting] = useState('');
  const [clinicGroups, setClinicGroups] = useState([]);

  useEffect(() => {
    fetchClientGroups()
  }, [])

  const fetchClientGroups = async () => {
    try {
      setIsLoading(true);
      const {data} = await ClientGroupsService.getClientGroups(clientInformation.id);
      setClinicGroups(data);
      setName('')
      setIsLoading(false);
    } catch(error) {
      setIsLoading(false);
      toast.error(<ErrorToast message="Error getting clinic groups" />);
    }
  }

  const createGroup = async () => {
    try {
      setIsCreating(true);
      await ClientGroupsService.createClientGroup({ name, labId: clientInformation.id });
      await fetchClientGroups();
      setIsCreating(false);
      toggleModal(false);
    } catch(error) {
      setIsCreating(false);
      toast.error(<ErrorToast message="Error getting clinic groups" />);
    }
  }

  const deleteClientGroup = async (e, groupId) => {
    try {
      e.stopPropagation();
      setIsDeleting(groupId);
      await ClientGroupsService.deleteClientGroup(groupId);
      await fetchClientGroups(clientInformation.id);
      setIsDeleting(null);
    } catch(error) {
      setIsDeleting(null);
      toast.error(<ErrorToast message="Error getting clinic groups" />);
    }
  }

  const onRowClick = (e, group) => {
    e.stopPropagation();
    history.push({ pathname: `/client-group/${group.id}` , state: {subClients} });
  }

  const renderTableData = () => {
    if(clinicGroups && clinicGroups.length > 0)
      return clinicGroups.map(group => {
        return (
          <Table.Row style={{ cursor: 'pointer' }} className="table-row" onClick={(e) => onRowClick(e, group)}>
            <Table.Cell>
              <div className="client-groups__table__user-abbreviation">
                <Text>{group.name.charAt(0)}</Text>
              </div>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{group.name}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{group.clients ? group.clients.length : 0}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text size="small">{moment(group.createdAt).format('DD/MM/YYYY')}</Text>
            </Table.Cell>
            <Table.Cell>
              <Button
              size="fluid"
              color='white'
              onClick={(e) => deleteClientGroup(e, group.id)}
              isLoading={isDeleting === group.id}
              >
                <Icon name="bin" color="red" fontSize="1.2em" />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
  };

  return (
    <div className="client-groups">
      <Button
      size="medium"
      color="blue"
      onClick={() => toggleModal(true)}
      >
      Create group
      </Button>
      <div className="client-groups__table-wrapper">
          <Table selectable unstackable>
              <Table.Header>
              <Table.Row>
                  <Table.HeaderCell style={{ width: '80px' }} />
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Clinics</Table.HeaderCell>
                  <Table.HeaderCell>Created on</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '80px' }} />
              </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
          </Table>
      </div>
      <Modal
        className="client-groups__modal"
        size="tiny"
        open={isModalToggled}
        onClose={() => toggleModal(false)}
      >
      <Modal.Header>Create group</Modal.Header>
      <Modal.Content>
      <div className="client-groups__modal__content">
        <Input
          className="create-client__content__form__input"
          label="Group name"
          placeholder="Enter group name"
          size="fullWidth"
          value={name}
          onChange={e => setName(e.target.value)}
        />
          <Button className="client-groups__modal__button" onClick={createGroup} isLoading={isCreating} color="blue" size="fullWidth">Create</Button>
      </div>
      </Modal.Content>
    </Modal>
    </div>
  );
};

export default ClinicGroups;
