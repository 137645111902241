import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Text from './Text';
import colors from '../styles/colors';

const TextArea = memo(props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  return (
    <Wrapper>
      <Text type="label">{props.label}</Text>
      <TextAreaWrapper
        className={props.className}
        placeholder={props.placeholder}
        style={props.style}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        invalid={props.invalid}
        clientBranding={clientBranding}
      />
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          This field is required
        </Text>
      )}
    </Wrapper>
  );
});

TextArea.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TextArea.defaultProps = {
  placeholder: 'Start typing here...',
  disabled: false,
};

const getBorder = invalid => {
  if (invalid) return `solid 1px #ff5252;`;

  return `solid 1px ${colors.GREY};`;
};

const getBorderHighlight = (clientColor) => {
  return `outline: none; border: solid 1px ${clientColor || colors.HIGHLIGHT_BLUE};`;
};

const TextAreaWrapper = styled.textarea`
  border: ${props => getBorder(props.invalid)};
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  font-size: 17px;
  color: ${colors.DARK_GREY};
  font-weight: 200;
  margin-top: 6px;

  &:disabled {
    background: repeating-linear-gradient(
      45deg,
      ${colors.FADED_WHITE},
      ${colors.FADED_WHITE} 10px,
      white 10px,
      white 20px
    );
  }

  ::placeholder {
    color: ${colors.DARK_GREY};
    opacity: 0.6;
    transition: all 0.3s ease;
    font-weight: 200;
    font-size: 16px;
  }

  &:focus {
    outline: none;
    ${props => getBorderHighlight(props.clientBranding?.color)}

    ::placeholder {
      color: ${colors.GREY};
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
`;

export default TextArea;
