import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.559 2.917c-.294.504-.534.94-.534.97 0 .082.143.192.54.417.199.113.356.217.348.23l-3.762 6.493a1015.403 1015.403 0 0 0-3.87 6.711c-.277.548-.36 1.366-.2 1.986.492 1.917 2.559 2.833 4.334 1.92.245-.125.398-.244.687-.535l.373-.374 1.195-2.07a2209.64 2209.64 0 0 1 2.102-3.633 7141.307 7141.307 0 0 0 4.353-7.512c.004-.004.19.097.413.225.429.247.54.276.637.165.136-.155 1.1-1.87 1.1-1.955 0-.05-.015-.107-.034-.125-.019-.018-1.517-.887-3.329-1.932l-3.067-1.767a.98.98 0 0 0-.49-.131.457.457 0 0 0-.395.227l-.401.69Zm2.973 3.094 1.818 1.046c.01.007-3.362 5.82-3.398 5.856-.016.017-.178-.093-.36-.244-.409-.34-.775-.533-1.453-.767-.795-.275-1.198-.5-1.672-.938l-.165-.152.177-.293.208-.347c.025-.041.159.02.589.269.306.177.566.312.578.3.04-.04.328-.543.328-.573 0-.016-.18-.131-.402-.256-.221-.125-.47-.269-.552-.32l-.15-.093.146-.262c.08-.144.156-.274.17-.29.013-.015.156.05.317.143l.294.17.165-.28c.091-.153.166-.296.166-.318.001-.021-.123-.108-.274-.193-.151-.085-.27-.172-.264-.195.028-.103.29-.529.325-.529.022 0 .278.14.57.31.604.352.525.36.775-.08l.127-.223-.565-.325-.565-.324.104-.18.614-1.066c.28-.487.516-.886.524-.887.007 0 .829.468 1.825 1.04Zm6.588 1.215c-.625.807-1.08 1.643-1.25 2.302a2.326 2.326 0 0 0-.087.817c.012.409.02.448.16.73.65 1.308 2.619 1.11 2.997-.302.183-.68-.057-1.632-.66-2.629-.256-.422-.84-1.22-.894-1.22-.017 0-.136.136-.266.302Z"
      fill={props.color}
    />
  </svg>
)

export default SvgComponent
