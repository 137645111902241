// eslint-disable-next-line import/no-cycle
import AuthService from '../api/auth';
import history from '../history';
import store from '../store';
import { setUser } from '../redux/generalState.actions';

export const clearUserStorage = (rootId) => {
  // clearInterval(window.refreshToken);
  sessionStorage.clear();
  localStorage.clear();
  localStorage.setItem('ROOT_ID', rootId);
};

export const createUserStorage = data => {
  localStorage.setItem('DP_AUTH_TOKEN', JSON.stringify(data.tokens.access));
  localStorage.setItem('DP_REFRESH_TOKEN', JSON.stringify(data.tokens.refresh));
  localStorage.setItem('DP_USER_ID', data.user.id);
  localStorage.setItem('DP_USER_INFO', JSON.stringify(data.user));
};

export const setTokens = tokens => {
  localStorage.setItem('DP_AUTH_TOKEN', JSON.stringify(tokens.access));
  localStorage.setItem('DP_REFRESH_TOKEN', JSON.stringify(tokens.refresh));
};

export const setClientStorage = client => {
  localStorage.setItem('DP_ISSUER', client.name);
  localStorage.setItem('DP_ROOT_CLIENT_ID', client.id);
  localStorage.setItem('DP_CLIENT_LOGO', client.logo);
};

export const signOutUser = async (redirect) => {
  try {
    window.Intercom('shutdown');
    const rLocalToken = localStorage.getItem('DP_REFRESH_TOKEN');
    const rootId = localStorage.getItem('ROOT_ID');
    if(rLocalToken) {
      const rToken = JSON.parse(rLocalToken).token;
      await AuthService.logOff(rToken);
    }
    clearUserStorage(rootId);
    if(redirect) history.push('/')
  } catch (error) {
    history.push('/')
  }
};

export const refreshUserToken = async () => {
  const rtoken = localStorage.getItem('DP_REFRESH_TOKEN');
  if(!rtoken) return signOutUser(true);
  try {
    const path = history.location.pathname
    const {data} = await AuthService.refreshToken({ refreshToken: rtoken.token });
    store.dispatch(setUser(data.user));
    createUserStorage(data);
    if(path === '/') history.push('/results');
  } catch(error) {
    console.log("refreshUserToken error", error)
    signOutUser(true);
  }
}

export const checkIfTokenExist = () => !!localStorage.getItem('DP_AUTH_TOKEN');
