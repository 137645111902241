import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.761 8.207c-.28-.65-1.055-1.143-1.722-1.096-.668.046-1.44-.215-1.782-.54-.342-.326-.619-1.097-.594-1.768.026-.672-.491-1.424-1.151-1.687-.66-.263-1.553-.063-1.993.44-.439.501-1.175.87-1.643.885-.467.014-1.212-.337-1.666-.826-.454-.49-1.355-.665-2.005-.384-.65.28-1.146 1.055-1.1 1.723.048.668-.213 1.44-.54 1.782-.325.342-1.095.616-1.763.594-.667-.021-1.423.493-1.686 1.154-.263.66-.063 1.554.439 1.993.502.44.871 1.175.885 1.642.015.466-.334 1.213-.826 1.667-.49.453-.667 1.354-.383 2.007.284.654 1.055 1.147 1.722 1.1.668-.047 1.44.214 1.782.54.342.326.62 1.093.594 1.764-.025.672.491 1.424 1.153 1.687.663.263 1.553.063 1.993-.44.439-.502 1.173-.87 1.64-.878.469-.007 1.214.34 1.67.83.458.49 1.358.657 2.007.376.65-.28 1.147-1.05 1.1-1.719-.047-.668.214-1.44.54-1.782.325-.342 1.094-.616 1.765-.589.671.027 1.424-.491 1.686-1.153.263-.663.067-1.554-.439-1.997-.505-.443-.874-1.17-.887-1.644-.012-.473.337-1.213.83-1.667a1.924 1.924 0 0 0 .374-2.014Zm-7.378 7.01a3.5 3.5 0 0 1-4.605-1.827 3.504 3.504 0 0 1 4.509-4.644 3.504 3.504 0 0 1 .101 6.47h-.005Z"
      fill={props.color}
    />
  </svg>
)

export default SvgComponent
