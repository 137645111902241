import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive'

import { HeaderDropdown, IconDropdownItem, Text, Select } from '../../components';
import { signOutUser } from '../../auth/authentication';
import { setUser } from '../../redux/generalState.actions';
import UserService from '../../api/user';
import {changeBarState} from '../../redux/sideBarState.reducer';


import './headerBar.scss';

const HeaderBar = props => {
  const dropdownToggleRef = useRef();
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.general.user);
  const clientBranding = useSelector(state => state.general.clientBranding);
  const userClients = useSelector(state => state.general.userClients);

  // --- Local state ---
  // const [notifs, setNotifs] = useState([]);
  // const [isNotificationsToggled, setIsNotificationsToggled] = useState(false);
  const [isSettingsToggled, setIsSettingsToggled] = useState(false);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [isVisible,setIsVisible] = useState(true);
  
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  useEffect(() => {
    formatClinicOptions();
    dispatch(changeBarState('', { type:'set', sidebarShow: isVisible }))
    if (!isPortrait){
      dispatch(changeBarState('', { type:'set', sidebarShow: true } )) 
       
    }
    if(isPortrait){
      dispatch(changeBarState('', { type:'set', sidebarShow: false }))      
        document.querySelector('.pushable').style.width = '1px';      
    }

  }, [userClients, userInfo])

  if (!isPortrait){
    dispatch(changeBarState('', { type:'set', sidebarShow: true }))     
  }
  
  const formatClinicOptions = () => {
    if(!userClients || userClients.length === 0) return
    const clinicz = userClients.map(clinic => ({ text: clinic.name, value: clinic.id }))
    setClinicOptions(clinicz);
  }

  const hide = () => {
    setIsSettingsToggled(!isSettingsToggled);
  };

  const signUserOut = () => {
    signOutUser(true)
  }

  const setSelectedUserClinic = async (clientId) => {
    await UserService.updateUser(userInfo.id, { clientId })
    dispatch(setUser({...userInfo, clientId }));
    window.location.reload();
  }

  const hideShowSideBar = () => {
    setIsVisible(!isVisible);  
   
    dispatch(changeBarState('',{type:'set',sidebarShow: isVisible}))
      if(!isVisible){
        document.querySelector('.pushable').style.width='1px';
      }else{
        document.querySelector('.pushable').style.width='80px';
      }
  }


  if(!isPortrait && document.querySelector('.pushable')){
    document.querySelector('.pushable').style.width='80px'
  }

  return (
    <>
      <HeaderBarContainer className="header-bar">
        <LogoContainer>
           <Icon name="bars" size="large" onClick={()=>hideShowSideBar()}/>
          <LogoImage
            alt="logo"
            src={clientBranding.logo}
          />
        </LogoContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <NotificationsContainer>
          <Dropdown direction="left" icon="announcement">
            <Dropdown.Menu className="header-bar__notif">
              {notifs.length > 0 ? (
                <>{notifs}</>
              ) : (
                <Dropdown.Item className="header-bar__notif">
                  <Text size="tiny">No new notifications</Text>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </NotificationsContainer> */}
        {clinicOptions && clinicOptions.length > 0 && (
          <div style={{ marginRight: '50px' }}>
            <Select
              border
              selectClassName="header-bar__select"
              search
              value={userInfo.clientId}
              onChange={(e, { value }) => setSelectedUserClinic(value)}
              options={clinicOptions}
            />
          </div>
        )}
        <div className='profile-dropdown'>
        <div id="name-avatar">
          <IconContainer ref={dropdownToggleRef} onClick={() => hide()}>
            <Letter>
              <Text>{userInfo.name ? userInfo.name.charAt(0) : ''}</Text>
            </Letter>
            <div id="username">
            <Text>{userInfo.name}</Text>
            </div>
          </IconContainer>
          </div>
        </div>
        {isSettingsToggled && (
          <div className='profile-dropdown profile-dropdown--toggled' >
            <IconContainer ref={dropdownToggleRef} onClick={() => hide()}>
              <Letter>
                <Text>{userInfo.name ? userInfo.name.charAt(0) : ''}</Text>
              </Letter>
              <Text>{userInfo.name}</Text>
            </IconContainer>
            
              <HeaderDropdown
                closeModal={() => setIsSettingsToggled(false)}
                toggleButtonRef={dropdownToggleRef}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={clientBranding.termsConditionsPath || "https://riverr.ai/wp-content/uploads/2021/02/Riverr-Pte.-Ltd.-Terms-Of-Use-Last-updated_-1-Februar-2021.pdf"}
                >
                  <IconDropdownItem
                    icon="law"
                    text="Terms & conditions"
                  />
                </a>
                <IconDropdownItem
                  icon="edit"
                  text="Reset password"
                  onClick={() => props.setIsModalToggled(true)}
                />
                <IconDropdownItem
                  icon="power off"
                  text="Sign out"
                  onClick={() => signUserOut()}
                />
              </HeaderDropdown>
          </div>
        )}
        </div>
      </HeaderBarContainer>
    </>
  );
};

const HeaderBarContainer = styled.div`
  width: 100vw;
  height: 55px;
  border: none;
  border-radius: 0;
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  min-width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const LogoImage = styled.img`
  width: auto;
  height: 40px !important;
  margin: auto;
  max-height: 50px;
  max-width: 300px;
  object-fit: contain;
`;

const IconContainer = styled.div`
  width: 100%;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
`;

const Letter = styled.div`
  height: 37px !important;
  width: 37px;
  background: #efefef;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 10px;
  padding-top: 2px;
`;

export default withRouter(HeaderBar);
