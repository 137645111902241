import {
  SET_USER,
  SET_USER_CLIENTS,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_BRANDING,
  SET_CLIENT_CLINICS
} from './generalState.actions';

const initialState = {
  clientInformation: {},
  user: {},
  clientBranding: {},
  userClients: [],
  clientClinics:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    case SET_USER_CLIENTS:
      return { ...state, userClients: action.payload };

    case SET_CLIENT_INFORMATION:
      return { ...state, clientInformation: action.payload };
    
    case SET_CLIENT_BRANDING:
      return { ...state, clientBranding: action.payload };

    case SET_CLIENT_CLINICS:
       return {...state, clientClinics:action.payload};
    default:
      return state;
  }
};
