import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderBy } from 'lodash';
import history from '../../history';
import ClientGroupsService from '../../api/clientGroups';
import ClientService from '../../api/clients';
import UserService from '../../api/user';

import { Text, Button, ErrorToast, Icon, Select } from '../../components';

import './clientGroupDetails.scss';

const ClinicGroups = (props) => {
  const clientInformation = useSelector(
      state => state.general.clientInformation
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState('');
  const [selectedClinic, setClinic] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [clinicGroup, setClinicGroup] = useState(null);
  const [subClinics, setSubClinics] = useState(null);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [groupUsers, setGroupUsers] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [groupActiveUsers, setGroupActiveUsers] = useState(null);

  useEffect(() => {
    fetchClientGroup();
    fetchSubClients();
    fetchGroupUsers();
    fetchGroupActiveUsers();
  }, [])

  useEffect(() => {
    filterClinics()
  }, [subClinics, clinicGroup])

  useEffect(() => {
    filterUsers()
  }, [groupActiveUsers, groupUsers])

  const filterUsers = () => {
    const activeUsersObj = {};
    const options = [];
    if(!groupActiveUsers || !groupUsers) return;

    groupActiveUsers.forEach(user => {
      activeUsersObj[user.id] = user.name
    })

    groupUsers.forEach(user => {
      if(!activeUsersObj[user.id]) options.push({ text: user.name.charAt(0).toUpperCase() + user.name.slice(1), value: user.id })
    })

    setUserOptions(options)
  }

  const fetchSubClients = async () => {
    try {
      if (!!props.location?.state?.subClinics && !!props.location?.state?.subClinics.length > 0) return setSubClinics(props.location.state.subClinics)
      const clientId = clientInformation.id;
      const { data } = await ClientService.getSubClients(clientId);
      setSubClinics(data)
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchGroupUsers = async () => {
    try {
      const groupId = props.match.params.id;

      const { data } = await ClientGroupsService.getClientGroupUsers(groupId);
      setGroupUsers(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchGroupActiveUsers = async () => {
    try {
      const groupId = props.match.params.id;

      const { data } = await ClientGroupsService.getClientGroupActiveUsers(groupId);
      setGroupActiveUsers(data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const filterClinics = async () =>{
    const filteredClinics = [];
    const clinicsObj = {}

    if(!clinicGroup || !subClinics) return;
    clinicGroup.clients.forEach((clinic) => {
      clinicsObj[clinic.id] = clinic.name;
    })
    subClinics.forEach((clinic) => {
      if(!clinicsObj[clinic.id]) filteredClinics.push({ text: clinic.name.charAt(0).toUpperCase() + clinic.name.slice(1), value: clinic.id });
    })

    setClinicOptions(orderBy(filteredClinics, ['text'], 'asc'));
  }

  const fetchClientGroup = async () => {
    try {
      const groupId = props.match.params.id;

      setIsLoading(true);
      const {data} = await ClientGroupsService.getClientGroup(groupId);
      setClinicGroup(data);
      setIsLoading(false);
    } catch(error) {
      console.log("error", error)
      setIsLoading(false);
      toast.error(<ErrorToast message="Error getting clinic group" />);
    }
  }

  const addUserToGroup = async () => {
    let newUser = null;

    try {
      if(!selectedUser) return;
      setIsAdding(true);
      groupUsers.forEach(user => {
        if(user.id === selectedUser) newUser = user;
      })
      let clientGroups = [{ name: clinicGroup.name, id: clinicGroup.id }]
      if(newUser.clientGroups) clientGroups = [...clientGroups, ...newUser.clientGroups];
      await UserService.updateUser(newUser.id, {clientGroups})
      await fetchGroupActiveUsers();
      setIsAdding(false);
    } catch(error) {
      console.log("error", error)
      setIsAdding(false);
      toast.error(<ErrorToast error={error} />);
    }
  }

  const addClinicToGroup = async () => {
    let newClinic = null;
    try {
      if(!selectedClinic) return;
      setIsAdding(true);
      clinicOptions.forEach(clinic => {
        if(clinic.value === selectedClinic) newClinic = clinic;
      })
      if(!newClinic) return;
      await ClientGroupsService.updateClientGroup(clinicGroup.id, {...clinicGroup, clients: [...clinicGroup.clients, { name: newClinic.text, id: newClinic.value}]})
      await fetchClientGroup();
      await fetchGroupUsers();
      setIsAdding(false);
    } catch(error) {
      console.log("error", error)
      setIsAdding(false);
      toast.error(<ErrorToast error={error} />);
    }
  }

  const removeClinicFromGroup = async (e, clinicId) =>{
    try {
      e.stopPropagation();
      setIsDeleting(clinicId);
      const newClientGroup = [];
      clinicGroup.clients.forEach(client => {
        if(client.id !== clinicId) newClientGroup.push(client);
      })
      await ClientGroupsService.updateClientGroup(clinicGroup.id, {...clinicGroup, clients: newClientGroup })
      await fetchClientGroup();
      setIsDeleting(null);
    } catch(error) {
      setIsDeleting(null);
      toast.error(<ErrorToast error={error} />);
    }
  }

  const removeGroupFromUser = async (e, userId) =>{
      let newUser = null;
      e.stopPropagation();
  
      try {
        setIsDeleting(userId);
        groupUsers.forEach(user => {
          if(user.id === userId) newUser = user;
        })
        let clientGroups = [...newUser.clientGroups].filter(group => (group.id !== clinicGroup.id))
        await UserService.updateUser(newUser.id, {clientGroups})
        await fetchGroupActiveUsers();
        setIsDeleting(null);
      } catch(error) {
        console.log("error", error)
        setIsAdding(null);
        toast.error(<ErrorToast error={error} />);
      }
    }

  const renderTableData = (data, isEmail, deleteCallback) => {
    if (data && data.length > 0)
      return data.map(group => {
        return (
          <Table.Row className="table-row">
            <Table.Cell>
              <Text size="small">{group.name}</Text>
            </Table.Cell>
            {isEmail && (
              <Table.Cell>
                <Text size="small">{group.email}</Text>
              </Table.Cell>
            )}
            <Table.Cell>
            <Button
              size="fluid"
              color='white'
              onClick={(e) => deleteCallback(e, group.id)}
              isLoading={isDeleting === group.id}
              >
                <Icon name="bin" color="red" fontSize="1.2em" />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
  };

  return (
    <div className="client-group">
      <div className="client-group__header">
        <Button
          icon
          size="xsmall"
          onClick={() => history.goBack()}
          color="blue"
          className="mr-3"
          backgroundColor='blue'
        >
            <Icon name="arrowLeft" color="white" fontSize="1.3em" />
        </Button>
        <Text bold size="bigger">{clinicGroup?.name}</Text>
      </div>
      <div className="client-group__tables">
      <div className="client-group__table-wrapper">
        <div className="client-group__table-wrapper__inputs">
          <Select
            label={<span>Select a <b>clinic</b> to add</span>}
            placeholder="Clinics"
            search
            border
            value={selectedClinic}
            onChange={(e, { value }) => setClinic(value)}
            size="small"
            options={clinicOptions}
          />
          <Button
          color='blue'
          onClick={addClinicToGroup}
          isLoading={isAdding}
          className="ml-2"
          >
            Add clinic
          </Button>
        </div>
          <Table className="client-group__table" collapsing>
              <Table.Header>
              <Table.Row>
                  <Table.HeaderCell>Clinic Name</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '80px' }} />
              </Table.Row>
              </Table.Header>
              <Table.Body>{(clinicGroup?.clients && clinicGroup.clients.length > 0) && renderTableData(clinicGroup.clients, false, removeClinicFromGroup)}</Table.Body>
          </Table>
      </div>
      <div className="client-group__table-wrapper">
        <div className="client-group__table-wrapper__inputs">
          <Select
            label={<span>Select a <b>user</b> to add</span>}
            placeholder="Users"
            search
            border
            value={selectedUser}
            onChange={(e, { value }) => setSelectedUser(value)}
            size="small"
            options={userOptions}
          />
          <Button
          color='blue'
          onClick={addUserToGroup}
          isLoading={isAdding}
          className="ml-2"
          >
            Add user
          </Button>
        </div>
          <Table className="client-group__table" collapsing>
              <Table.Header>
              <Table.Row>
                  <Table.HeaderCell>User Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '80px' }} />
              </Table.Row>
              </Table.Header>
              <Table.Body>{(groupActiveUsers && groupActiveUsers.length > 0) &&  renderTableData(groupActiveUsers, true, removeGroupFromUser)}</Table.Body>
          </Table>
      </div>
    </div>
    </div>
  );
};

export default withRouter(ClinicGroups);
