import axios from './axiosInstance';

const methods = {
  async deletePatient(patientId) {
    return axios.delete(`/patient/${patientId}`);
  },

  async createPatient(payload) {
    return axios.post('/patient', payload);
  },

  async updatePatient(payload) {
    return axios.put('/patient', payload);
  },

  async getPatients(filters) {
    return axios.get('/patient', { params: { ...filters } });
  },

  async getPatient(patientId) {
    return axios.get(`/patient/${patientId}`);
  },
};

export default methods;
