import axios from './axiosInstance';

const methods = {
  async createSampleCollection(payload) {
    return axios.post('/sample-collection', payload);
  },

  async getSampleCollections(filters) {
    return axios.get('/sample-collection', { params: filters });
  },
}

export default methods;
