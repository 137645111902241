import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  Input,
  Button,
  Text,
  ErrorToast,
  SuccessToast,
  CheckBox,
} from '../../components';
import history from '../../history';
import AuthService from '../../api/auth';

import './activateUser.scss';

const ActivateUser = props => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [formErrors, setErrors] = useState({});
  const [isAvtivating, setIsActivating] = useState(false);
  const [token, setToken] = useState('');
  const [accepted, setAccepted] = useState(false);

  const clientInfo = useSelector(
    state => state.general.clientBranding
  );

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    setToken(params.get('token'));
  }, []); // eslint-disable-line

  const checkForm = () => {
    let isError = false;
    let errors = {
      password: '',
      passwordConfirm: '',
    };

    if (password !== passwordConfirm) {
      errors.password = 'Passwords must match';
      errors.passwordConfirm = 'Passwords must match';
      isError = true;
    }

    if (password.length < 8) {
      errors.password = 'Min 8 characters';
      isError = true;
    }

    setErrors(errors);
    return isError;
  };

  const activateUser = async () => {
    const isError = checkForm();
    if (isError) return;
    setIsActivating(true);
    try {
      const user = await AuthService.activateUser(token, password);

      toast.success(<SuccessToast message="User activated" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      // TODO: user.email doesn't work but would be a good feature
      history.push({ pathname: '/', state: { email: user.email } });
    } catch (error) {
      toast.error(<ErrorToast message="Error activating user" />);
      setIsActivating(false);
    }
  };
  return (
    <div className="activate-user">
      <div className="activate-user__content">
        <div className="activate-user__title-wrapper">
          <Text size="huge" bold>
            Activate user
          </Text>
        </div>
        <div className="activate-user__body-wrapper">
          <Input
            placeholder="Password"
            label="Set password"
            size="fullWidth"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            invalid={formErrors.password && formErrors.password.length > 0}
            errorMessage={formErrors.password}
            autoComplete="new-password"
          />
          <Input
            placeholder="Confirm password"
            label="Confirm password"
            size="fullWidth"
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)}
            type="password"
            invalid={
              formErrors.passwordConfirm &&
              formErrors.passwordConfirm.length > 0
            }
            errorMessage={formErrors.passwordConfirm}
            autoComplete="new-password"
          />
          <div className="activate-user__body-wrapper__checkbox">
            <CheckBox
              onClick={() => setAccepted(!accepted)}
              checked={accepted}
            />
            <Text size="small" className="ml-4">
              I accept the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={clientInfo.termsConditionsPath || "https://riverr.ai/wp-content/uploads/2021/02/Riverr-Pte.-Ltd.-Terms-Of-Use-Last-updated_-1-Februar-2021.pdf"}
              >
                terms
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={clientInfo.privacyPolicy || "https://riverr.ai/wp-content/uploads/2021/02/Riverr-Pte.-Ltd.Privacy-Policy-Last-updated_-1-Februar-2021.pdf"}
              >
                privacy policy
              </a>
            </Text>
          </div>

          <Button
            disabled={!accepted}
            color={
              props.clientInformation && props.clientInformation.color
                ? props.clientInformation.color
                : 'blue'
            }
            size="fullWidth"
            onClick={() => activateUser()}
            isLoading={isAvtivating}
          >
            Set password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ActivateUser);
