import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import general from './redux/generalState.reducer';
import {changeBarState} from './redux/sideBarState.reducer';

const reducer = combineReducers({
  general,
  changeBarState
});

export default createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk))
);
