import React, { useState } from 'react';
import { Table, Dropdown, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import UserService from '../../../../api/user';
import { Text, Select, Button, Input, ErrorToast } from '../../../../components';

import './clientUsers.scss';

const ClientUsers = ({ fetchClientUsers, users, match }) => {
  const [isDeleting, setIsDeleting] = useState('');
  const [isInviting, setIsInviting] = useState('');
  const [isModalToggled, toggleModal] = useState(false);
  const [userRole, setRole] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const clientInfo = useSelector(state => state.general.clientInformation);
  const userInfo = useSelector(state => state.general.user);

  const inviteUser = async () => {
    setIsInviting(true);
    try {
      const clientId = match.params.id || clientInfo.id;
      const rootId = localStorage.getItem('ROOT_ID');

      await UserService.createUser({
        name: userName,
        email: userEmail,
        clientId,
        role: userRole,
        labId: rootId
      });
      await fetchClientUsers();
      setUserName('');
      setUserEmail('');
      setIsInviting(false);
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsInviting(false);
    }
  };

  const deleteUser = async userId => {
    try {
      setIsDeleting(userId);
      await UserService.deleteUser(userId);
      setIsDeleting('');
      fetchClientUsers();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsInviting(false);
    }
  };

  const changeUserRole = async (userId, role) => {
    try {
      await UserService.updateUser(userId, {role})
      await fetchClientUsers();
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    }
  }
 
  const renderTableData = () => {
    return users.map(user => {
      return (
        <Table.Row className="table-row">
          <Table.Cell>
            <div className="client-users__table__user-abbreviation">
              <Text>{user.name.charAt(0)}</Text>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{user.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{user.email}</Text>
          </Table.Cell>
          <Table.Cell>
            {userInfo.role === 'admin' ? 
              <Select
                border
                search
                icon={false}
                size="fullWidth"
                options={[
                { text: 'Admin', value: 'admin' },
                { text: 'User', value: 'user' },
                ]}
                value={user.role}
                onChange={(e, { value }) => changeUserRole(user.id, value)}
              />
            :
              <Text size="small">{user.role}</Text>
            }
          </Table.Cell>
          <Table.Cell>
            {!user.isActivated ? (
              <div className="client-users__user-status client-users__user-status--red">
                <Text size="tinniest" color="white">
                  NOT ACTIVATED
                </Text>
              </div>
            ) : (
              <div className="client-users__user-status client-users__user-status--green">
                <Text size="tinniest" color="white">
                  ACTIVATED
                </Text>
              </div>
            )}
          </Table.Cell>
          <Table.Cell>
            <Text size="tiny" color="grey">{user.lastLogin ? moment(user.lastLogin).format('DD/MM/YYYY HH:mm') : '---'}</Text>
          </Table.Cell>
          <Table.Cell className="table-row__cell--center">
            <Dropdown
              className="user-management__more-dropdown"
              icon="ellipsis vertical"
              floating
              labeled
              button
              direction="left"
              loading={isDeleting === user.id}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Delete user"
                  onClick={() => deleteUser(user.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="client-users">
      <div className="client-users__header">
        <Text bold size="bigger">Users</Text>
        <Modal
          className="client-details__modal-wrapper"
          size="mini"
          open={isModalToggled}
          onClose={() => toggleModal(false)}
          trigger={
            <Button
              size="medium"
              color="blue"
              onClick={() => toggleModal(true)}
            >
              Invite user
            </Button>
          }
        >
          <Modal.Content>
            <div className="client-details__modal">
              <Text color="grey" dark>Invite a new user</Text>
              <div className="client-details__modal__content">
                <Input
                  label="Name"
                  border
                  placeholder="Name"
                  size="fullWidth"
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                />
                <Input
                  label="Email"
                  border
                  placeholder="Email"
                  size="fullWidth"
                  value={userEmail}
                  onChange={e => setUserEmail(e.target.value)}
                />
                  <Select
                      label="Role"
                      border
                      search
                      icon={false}
                      size="fullWidth"
                      placeholder="Select role"
                      options={[
                      { text: 'Admin', value: 'admin' },
                      { text: 'User', value: 'user' },
                      ]}
                      value={userRole}
                      onChange={(e, { value }) => setRole(value)}
                  />
              </div>
              <Button
                size="fullWidth"
                onClick={() => {
                  inviteUser();
                }}
                bold
                color="blue"
                isLoading={isInviting}
                className="mt-3"
              >
                Invite user
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
      <div className="client-users__table-wrapper">
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: '80px' }} />
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Last login</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '80px' }} />
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableData()}</Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default withRouter(ClientUsers);
