import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Pagination } from 'semantic-ui-react';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Text, Search, Select } from '../../components';
import PatientService from '../../api/patient';
import history from '../../history'

import './patients.scss';

const Patients = () => {
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const userClients = useSelector(state => state.general.userClients);

  const [patients, setPatients] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [paginationInfo, setPaginationInfo] = useState({ totalPages: 1 });
  const [activePage, setActivePage] = useState(1);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);

  useEffect(() => {
    if(clinicOptions.length > 0 && clientInformation.type !== 'LAB') setSelectedClinics([clientInformation.id])
  }, [clinicOptions])

  const handleSearch = useCallback(
    debounce(
      ({ search}) => fetchPatients({ search, selectedClins: selectedClinics }),
      500
    ),
    [selectedClinics]
  );

  useEffect(() => {
    formatClinicOptions();
  }, [userClients])

useEffect(() => {
    fetchPatients({});
  }, [selectedClinics]);

  const formatClinicOptions = () => {
    if(!userClients || userClients.length === 0) return
    const clinicz = userClients.map(clinic => ({ text: clinic.name, value: clinic.id }))
    setClinicOptions(clinicz);
  }

  const onSearchChange = search => {
    setSearchValue(search);
    handleSearch({ search });
  };

  const fetchPatients = async ({ search = null, page = activePage, selectedClins = selectedClinics  }) => {
    setSearching(true);
    const searchParams = { page }
    if(!!search) searchParams.search = search;

    let selectedClinicz = [...selectedClins];
    if (clinicOptions.length > 0 && clientInformation.type !== 'LAB' && selectedClins && selectedClins.length === 0)
      selectedClinicz = clinicOptions.map((clinic) => clinic.value);
      
    if(clinicOptions.length > 0 && clientInformation.type !== 'LAB' && selectedClinicz) searchParams.clients = selectedClinicz

    const {data} = await PatientService.getPatients(searchParams)
    setPatients(data.results);
    delete data.results;
    setPaginationInfo(data);
    setSearching(false);
  }

  const onRowClick  = (patient) => {
    history.push({
      pathname:`/patients/${patient.id}`,
      state: { patient }
    })
  }

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
    fetchPatients({ page: activePage })
  }

  const renderTableData = () => {
    return patients.map(patient => {
      return (
        <Table.Row className="table-row" onClick={() => onRowClick(patient)}>
          <Table.Cell>
            <Text size="small">{patient.fullName}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small" uppercase>{patient.gender}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{moment(patient.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{patient.email}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{patient.ssn || patient.passNo}</Text>
          </Table.Cell>
          {/* <Table.Cell className="table-row__cell--center">
            <Dropdown
              className="user-management__more-dropdown"
              icon="ellipsis vertical"
              floating
              labeled
              button
              direction="left"
              loading={isDeleting === client.id}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Delete clinic"
                  onClick={() => deleteClient(client.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell> */}
        </Table.Row>
      );
    });
  };

  return (
    <div className="patients">
      <div className="patients__header">
        <Text type="pageHeader">Patients</Text>
        <div style={{ display: 'flex' }}>
        {clinicOptions?.length > 0 && clientInformation.type !== 'LAB' && (
          <div className="mr-3" style={{ position: 'relative' }}>
            <Text
              size="tinniest"
              color="lightGrey"
              className="results__search__date-tag"
            >
              Clinics
            </Text>
            <Select
              search
              multiple
              border
              icon={false}
              placeholder="All clinics"
              className="results__client-select"
              options={clinicOptions}
              value={selectedClinics}
              onChange={(e, { value }) => setSelectedClinics(value)}
            />
          </div>
        )}
        <Search
          isLoading={isSearching}
          size="large"
          dropdown={false}
          onSearchChange={(e, { value }) => onSearchChange(value)}
          isFilter={true}
        />
        </div>
      </div>
        <div className="patients__table-wrapper">
            <Table selectable unstackable striped>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Gender</Table.HeaderCell>
                    <Table.HeaderCell>Date of birth</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>NRIC/FIN/Pass</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>{renderTableData()}</Table.Body>
            </Table>
        </div>
        {!isSearching && paginationInfo.totalPages > 1 && paginationInfo.page && (
          <Pagination
            size="small"
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={paginationInfo.totalPages}
            firstItem={null}
            lastItem={null}
          />
        )}
    </div>
  );
};

export default withRouter(Patients);
