import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MobilePDFReader  } from 'react-read-pdf';
import { Document, Page, pdfjs } from 'react-pdf';

import DownloadPdf from '../../assets/icons/download.png';
import {Text} from '../../components';

import './PDFViewer.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer= ({file,name,title})=>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <>
            {
                isTabletOrMobile ?
                    <MobilePDFReader url={file} />
                :
                    <iframe className="result-details__pdf-wrapper__pdf" src={file} name={name} frameBorder={0} title={name} />
                    // <div className="result-details__pdf-wrapper__pdf">
                    //     <div className="pdf-viewer__tool-bar">
                    //         <div/>
                    //         <div>
                    //             <Text color="white" bold size="small">{name}</Text>
                    //         </div>
                    //         <div>
                    //             <a className="pdf-viewer__download" download={name} href={file} title="Download">
                    //                 <Text bold size="tiny" className="mr-2">Download</Text>
                    //                 <img alt="download" src={DownloadPdf}/>
                    //             </a>
                    //         </div>
                    //     </div>
                    //     <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    //         {Array.from(new Array(numPages), (el, index) => (
                    //             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    //         ))}
                    //     </Document>
                    // </div>
            }
        </>
    )
}

export default PDFViewer