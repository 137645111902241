const API_V1_ENDPOINT = window.location.hostname === 'localhost' ? 'http://localhost:8080/v1' : 'https://prod.lab-portal.api.riverr.ai/v1';


const subdomains = {
  // localhost: '613ae68446debcb0c4ef818b', Test Eurofins account
  localhost: '6217a5e56289886cbf830ceb',
  master: '6217a5e56289886cbf830ceb',
  elab: '618246c4b98cddb71e0b96bd',
}

const uploadModes = {
  clientLogo: 'clientLogo',
  image: 'image',
}

export {
  uploadModes,
  API_V1_ENDPOINT,
  subdomains
};
