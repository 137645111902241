import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Pagination } from 'semantic-ui-react';
import { debounce } from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { toast } from 'react-toastify';
import {useMediaQuery} from 'react-responsive';

import { Text, Input, ErrorToast, Button, DatePicker, Cleave, Select, TextArea, Search } from '../../components';
import SampleCollectionService from '../../api/sampleCollection';

import './sampleCollection.scss';

const SampleCollection = (props) => {
  const [samples, setSamples] = useState([]);
  const [pickUpDate, setPickUpDate] = useState(moment().toDate());
  const [pickUpTimeFrom, setPickUpTimeFrom] = useState('09:00');
//   const [pickUpTimeTo, setPickUpTimeTo] = useState('11:00');
//   const [urgent, setUrgent] = useState(false);
  const [type, setType] = useState([]);
  const [total, setTotal] = useState('0');
  const [remark, setRemark] = useState('');
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({ totalPages: 1 });
  const [activePage, setActivePage] = useState(1);

  const [isSearching, setSearching] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('all');

  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const userInfo = useSelector(state => state.general.user);
  const clinicList = useSelector(state=>state.general.clientClinics);

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  // This is used to scroll to the top after every route change
  useEffect(() => {
    fetchClients()
    setUrlValuesToState();
  }, []);

  useEffect(() => {
    fetchSamples({});
  }, [fromDate, toDate,selectedClient]);

  if(isPortrait && document.querySelector('.sample-collection__form')){
      document.querySelector('.sample-collection__content').style.overflowX ='unset';
  }

  const handleSearch = useCallback(
    debounce(
      ({ search, fromDate, toDate,selectedClient }) => fetchSamples({ search, fDate: fromDate, tDate: toDate }),
      750
    ),
    [fromDate, toDate, activePage,selectedClient]
  );
 
   const fetchClients = async() => {
    if (clientInformation.type !== 'LAB') return;
     
     const clinicNames = clinicList.map(clinics => (
         {
           text: clinics.name,
           key: clinics.id,
           value: clinics.id
        }
     ))

     clinicNames.unshift({
        text: 'All clinics',
        key: 'all',
        value: 'all'
     })
   
     setClients(clinicNames)
    }
    
    const setUrlValuesToState = () => {        
        const params = new URLSearchParams(props.history.location.search);
        const search = params.get('search')
        const client = params.get('client')
        const page = params.get('page')
        const from = params.get('from') ? moment(params.get('from'), 'DD-MM-YYYY').toDate() : '';
        const to = params.get('to') ? moment(params.get('to'), 'DD-MM-YYYY').toDate() : '';
        setSearchValue(search);
        setFromDate(from);
        setActivePage(page || 1);
        setToDate(to);
        // setSelectedClient(client)
        fetchSamples({ search,client, fDate: from, tDate: to });
    }
    
    const updateSearchParams = (search,client, fDate, tDate, page) => {
        const newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          `?page=${page}&client=${client}&search=${search || ''}&from=${fDate ? moment(fDate).format('DD-MM-YYYY') : ''}&to=${tDate ? moment(tDate).format('DD-MM-YYYY') : ''}`
    
        window.history.pushState({ path: newurl }, '', newurl);
    };
      
        
    const onSearchChange = search => {
        setSearchValue(search);
        handleSearch({ search });
    };
   
   

    const fetchSamples = async ({search = searchValue,client=selectedClient, fDate = fromDate, tDate = toDate, page = activePage }) => {
        try {
            setSearching(true);
            const searchParams = { page };
            if(!!search) searchParams.search = search;
            if(!!client) searchParams.client = client;
            if(!!fDate) searchParams.from = moment.utc(fDate).add(1, 'day').startOf('day').toISOString();
            if(!!tDate) searchParams.to = moment.utc(tDate).add(1, 'day').endOf('day').toISOString()
            if(!clientInformation.parentClient) searchParams.labId=clientInformation.id;
            if(clientInformation.parentClient) searchParams.clinicId=clientInformation.id;
            const {data} = await SampleCollectionService.getSampleCollections(searchParams)
            setSamples(data.results);
            delete data.results;
            setPaginationInfo(data);
            updateSearchParams(search,client, fDate, tDate, page);
            setSearching(false);
        } catch(error) {
            console.log("error", error)
        }
    }

    const checkForm = () => {
        let isError = false;
        const errors = {
            pickUpDate: false,
            pickUpTimeFrom: false,
            // pickUpTimeTo: false,
            type: false,
            total: false,
        };
    
        if (!pickUpDate) {
          errors.pickUpDate = true;
          isError = true;
        }
    
        if (pickUpTimeFrom.length === 0) {
          errors.pickUpTimeFrom = true;
          isError = true;
        }
        // if (pickUpTimeTo.length === 0) {
        //   errors.pickUpTimeTo = true;
        //   isError = true;
        // }
    
        if (type.length === 0) {
          errors.city = true;
          isError = true;
        }

    
        if (isError) toast.error(<ErrorToast message="Please fill out all required fields" />)
        setErrors(errors);
        return isError;
      };

    const createCollectionSample = async () => {
        try {
            const hasError = checkForm();
            if (hasError) return;
            setIsSaving(true);
            await SampleCollectionService.createSampleCollection({
                labId: clientInformation.parentClient,
                clinicId: clientInformation.id,
                pickUpDate: moment(pickUpDate).format('DD/MM/YYYY'),
                pickUpTimeFrom,
                // pickUpTimeTo,
                // urgent,
                ordererName: userInfo.name,
                ordererId: userInfo.id,
                type,
                total: parseInt(total),
                remark
            })
            await fetchSamples({});
            setIsSaving(false);
        } catch(error) {
            toast.error(<ErrorToast error={error} />)
            setIsSaving(false);
            console.log("error", error)
        }
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
        fetchSamples({ page: activePage })
      }

      
    const onSelectClient = ({ value }) => {
      
        setSelectedClient(value);
       
    }


    const renderTableData = () => {
        return samples.map(sample => {
            return (
            <Table.Row className="table-row">
                {!clientInformation.parentClient && (
                    <Table.Cell>
                        <Text size="small">{sample.clinicName}</Text>
                    </Table.Cell>
                )}
                {clientInformation.type === 'LAB' && <Table.Cell><Text size="small">{sample.ordererName}</Text></Table.Cell>}
                {clientInformation.type === 'LAB' && <Table.Cell><Text size="small">{sample.clinicPhone}</Text></Table.Cell>}
                <Table.Cell>
                    <Text size="small">{sample.pickUpDate}</Text>
                </Table.Cell>
                <Table.Cell>
                    <div className={cx('sample-collection__status-dot', {
                        'sample-collection__status-dot--red': !sample.type.includes('clinical'),
                        'sample-collection__status-dot--green': sample.type.includes('clinical'),
                    })}/>
                </Table.Cell>
                <Table.Cell>
                    <div className={cx('sample-collection__status-dot', {
                        'sample-collection__status-dot--red': !sample.type.includes('COVID'),
                        'sample-collection__status-dot--green': sample.type.includes('COVID'),
                    })}/>
                </Table.Cell>
                <Table.Cell>
                    <Text size="small">{sample.total}</Text>
                </Table.Cell>
                {clientInformation.type === 'LAB' && <Table.Cell>{sample.remark}</Table.Cell>}
            </Table.Row>
            );
        });
    };

    return (
    <div className="sample-collection">
        <div className="sample-collection__header">
            <Text type="pageHeader">Sample Collection</Text>
            <div className="results__search__section date-search">
                <div style={{ display: 'flex' }}>
                    <div style={{ position: 'relative', marginRight: '10px' }}>
                        <Text size="tinniest" color="lightGrey" className="results__search__date-tag">From</Text>
                        <DatePicker
                        className="lab-documents__date"
                        onChange={date => setFromDate(date)}
                        value={fromDate}
                        maxDate={toDate}
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Text size="tinniest" color="lightGrey" className="results__search__date-tag">To</Text>
                        <DatePicker
                        className="lab-documents__date"
                        onChange={date => setToDate(date)}
                        value={toDate}
                        minDate={fromDate}
                        clearIcon={true}
                    />
                    </div>
                </div>
                <Search            
                    isLoading={isSearching}
                    size="large"
                    value={searchValue}
                    dropdown={false}
                    onSearchChange={(e, { value }) => onSearchChange(value)}
                    isFilter={true}
                />
            </div>
        </div>
        <div className="sample-collection__content">
        {clientInformation.parentClient && (
                <div className="sample-collection__form">
                    <div className="sample-collection__form__grid">
                        <div style={{ position: 'relative' }}>
                            <Text type="label">Pick up date</Text>
                            <DatePicker
                                className="sample-collection__date"
                                onChange={date => setPickUpDate(date)}
                                value={pickUpDate}
                                minDate={moment().toDate()}
                                clearIcon={true}
                            />
                        </div>
                        <Cleave
                            label="Pick up from"
                            wrapperClassName="sample-collection__cleave"
                            placeholder={'hh:mm'}
                            options={{ time: true, timePattern: ['h', 'm'] }}
                            size="small"
                            value={pickUpTimeFrom}
                            onChange={event => setPickUpTimeFrom(event.target.value)}
                            invalid={errors.time}
                        />
                        {/* <Cleave
                            label="Pick up to"
                            wrapperClassName="sample-collection__cleave"
                            placeholder={'hh:mm'}
                            options={{ time: true, timePattern: ['h', 'm'] }}
                            size="small"
                            value={pickUpTimeTo}
                            onChange={event => setPickUpTimeTo(event.target.value)}
                            invalid={errors.endTime}
                        /> */}
                        <Select
                            label="Sample types"
                            placeholder="Select one or more types"
                            search
                            border
                            multiple
                            value={type}
                            invalid={errors.type}
                            onChange={(e, { value }) => setType(value)}
                            options={[{ text: 'COVID', value: 'COVID'}, { text: 'Clinical', value: 'clinical'}]}
                        />
                        <Input
                            border
                            type="number"
                            label="Sample Total"
                            placeholder="Total"
                            value={total}
                            invalid={errors.accountHolder}
                            size="tiny"
                            onChange={e => setTotal(e.target.value)}
                        />
                        {/* <div className="sample-collection__cleave">
                            <Text type="label">
                            Is urgent
                            </Text>
                            <Switch
                            onChange={() => setUrgent(!urgent)}
                            checked={urgent}
                            className="cert-template__valid-until-toggle"
                            />
                        </div> */}
                    </div>
                    <div className="sample-collection__text-area">
                        <TextArea label="Remarks" value={remark} onChange={(e) => setRemark(e.target.value)}/>
                    </div>
                    <Button
                        size="large"
                        isLoading={isSaving}
                        onClick={createCollectionSample}
                        color="blue"
                        className="mt-4"
                    >
                        Request Sample Collection
                    </Button>
                </div>
            )}
            <div className={clientInformation.type !== 'LAB' ? "sample-collection__table-wrapper" : "sample-collection__table-wrapper sample-collection__table-wrapper--lab"}>
                <Table striped unstackable>
                    <Table.Header>
                    <Table.Row>
                        {!clientInformation.parentClient && (
                            <Table.HeaderCell style={{ padding: '6px 10px' }}>
                                <Select
                                border
                                placeholder="Filter by clinic"
                                value={selectedClient}
                                onChange={(e,data)=>onSelectClient(data)}
                                search
                                selection
                                options={clients}
                                selectClassName='sample-collection__select-text'
                                />
                            </Table.HeaderCell>
                        )}
                        {clientInformation.type === 'LAB' && <Table.HeaderCell>Orderer</Table.HeaderCell>}
                        {clientInformation.type === 'LAB' && <Table.HeaderCell>Contact</Table.HeaderCell>}
                        <Table.HeaderCell style={{ minWidth: '77px' }}>Pick Up</Table.HeaderCell>
                        <Table.HeaderCell>Clinical</Table.HeaderCell>
                        <Table.HeaderCell>Covid</Table.HeaderCell>
                        <Table.HeaderCell>Samples</Table.HeaderCell>
                        {clientInformation.type === 'LAB' && <Table.HeaderCell style={{ width: '28%' }}>Remark</Table.HeaderCell>}
                    </Table.Row>
                    </Table.Header>
                    <Table.Body>{renderTableData()}</Table.Body>
                </Table>
                {samples.length > 0 && paginationInfo.totalPages > 1 && paginationInfo.page && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            size="small"
                            activePage={activePage}
                            onPageChange={handlePaginationChange}
                            totalPages={paginationInfo.totalPages}
                            firstItem={null}
                            lastItem={null}
                        />
                    </div>
                )}
            </div>
        </div>
    </div>
  );
};

export default withRouter(SampleCollection);
