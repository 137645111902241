import S3 from 'aws-s3-pro';
import { uploadModes } from '../constants';

// eslint-disable-next-line import/prefer-default-export
// hello comment
// redeploy
// redeploy redeploy
export const s3Upload = {
  data: () => ({
    fileName: '',
    folder: null,
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  config() {
    return {
      bucketName: 'riverr-portal',
      dirName: this.folder,
      region: 'ap-southeast-1',
      accessKeyId: 'AKIAWPJZVTVQNQTNUP6V',
      secretAccessKey: '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',
      s3Url: 'https://riverr-portal.s3-accelerate.amazonaws.com',
    };
  },
  S3Client() {
    return new S3(this.config());
  },
  newFileName() {
    return Math.random()
      .toString()
      .slice(2);
  },
  url() {
    return `https://riverr-portal.s3-accelerate.amazonaws.com/${this.folder}/${this.newFileName}`;
  },
  removeFile(type) {
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
    // this.$emit(`remove${typeCapitalized}`);
  },
  onFileChange(files, type) {
    this.uploadFileToS3(type, files);
  },
  uploadFileToS3(type = 'image', files) {
    const client = JSON.parse(localStorage.getItem('DP_USER_INFO'));
    const file = files;
    let folder = null;

    switch (type) {
      case uploadModes.clientLogo:
        folder = `${client.clientId}/client-logo`;
        break;
      default:
        folder = `${client.clientId}/images`;
    }

    this.folder = folder;
    return this.S3Client().uploadFile(file, this.newFileName());
  },
};
