import axios from './axiosInstance';

const methods = {
  async deleteUser(userId) {
    return axios.delete(`/user/${userId}`);
  },

  async createUser(payload) {
    return axios.post(`/user`, payload);
  },

  async updateUser(userId, payload) {
    return axios.put(`/user/${userId}`, payload);
  },

  async getUsers(filters) {
    return axios.get(`/user`, { params: { ...filters } });
  },

  async getUser(userId) {
    return axios.get(`/user/${userId}`);
  },
};

export default methods;
