import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from '../index';
// TODO: Move function to utils/helpers
const ErrorToast = props => {
  const filteredErrorMessage = () => {
    const { error } = props;

    if (!error || !error.message || !error.response?.data?.message) return 'Something went wrong';
    if(error.response.data.message) return error.response.data.message;
    if (error.message.indexOf(':'))
      return error.message.slice(
        error.message.indexOf(':') + 1,
        error.message.length
      );
    return error.message;
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text size="small">
        {props.message || filteredErrorMessage()}
      </Text>
    </div>
  );
};

ErrorToast.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
};

ErrorToast.defaultProps = {
  error: { message: '' },
};

export default ErrorToast;
