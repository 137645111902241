import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Loader, Tab,Grid } from 'semantic-ui-react';
import moment from 'moment';
import axios from 'axios';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import history from '../../history';
import { Text, Button, Icon, ErrorToast } from '../../components';
import ResultService from '../../api/result';
import TestService from '../../api/test';
import PDFViewer from '../../components/PDFViewer/PDFViewer'

import './resultDetails.scss';

const ResultDetails = ({ match }) => {
  const [resultDetails, setResultDetails] = useState({});
  const [tests, setTests] = useState({});
  const [resultFileBlobs, setResultFileBlobs] = useState([]);
  const [panes, setPanes] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // This is used to scroll to the top after every route change
  useEffect(() => {
    fetchResult();
    fetchTests();
  }, []);

  useEffect(() => {
    setTabPanes()
  }, [resultFileBlobs, resultDetails, isLoading])

 
  const fetchResult = async () => {
    try {
      setLoading(true);
      const {data} = await ResultService.getResult(match.params.resultId)
      const fileBlobs = data.signedPdfUrls.map(file => fileToBlob(file.url).then((res) => ({file: res, name: file.name})));
      const results = await Promise.all(fileBlobs);
      setResultFileBlobs(results);
      setResultDetails(data);
      setLoading(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoading(false);
    }
  }

  const fetchTests = async () => {
    const {data} = await TestService.getTests({ resultId: match.params.resultId })
    setTests(data)
  }

  const fileToBlob = async (file) => {
    const resp = await axios
    .get(file, {
      responseType: 'blob',
    })

    return new Promise((resolve) => {
      const newBlob = new Blob([resp.data], { type: 'application/pdf' });

      const reader = new FileReader()
      reader.readAsDataURL(newBlob)
      reader.onloadend = () => resolve(reader.result)
    })
  }

  const renderTableData = () => {
    return tests.map(test => {
      return (
        <Table.Row className="table-row">
          <Table.Cell>
            <Text>{test.name}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text>{test.code}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text uppercase>{test.status}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text>{moment(test.updatedAt).format('DD/MM/YYYY')}</Text>
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  const setTabPanes = () => {
    const tabPanes = [];

    if(resultFileBlobs && resultFileBlobs.length > 0)
      tabPanes.push({ menuItem: 'Lab Result', render: () => resultFileBlobs.map(result => (
        <div className="result-details__test-table mt-0">
          {isLoading ?
            <div className="result-details__side-details-wrapper__loader-wrapper">
              <Loader active className="result-details__side-details-wrapper__loader-wrapper__loader" size="small" />
            </div>
          : (
            <div className="result-details__side-details-wrapper mb-3 result-menu">
              <Text size="big" dark className="mb-2" color="grey">Order</Text>
               
                <div className="result-details__side-detail">
                  <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Number</Text>
                  <Text className="result-details__side-detail__data" size="small">{resultDetails.order.id}</Text>
                </div>
                
                <div className="result-details__side-detail">
                  <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Date</Text>
                  <Text className="result-details__side-detail__data" size="small">{moment(resultDetails.order.date).format('DD/MM/YYYY')}</Text>
                </div>

                <div className="result-details__side-detail">
                  <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Tests/Profiles</Text>
                  <Text className="result-details__side-detail__data" size="small">{resultDetails.testProfile}</Text>
                </div>

                <div className="result-details__side-detail">
                  <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Ordering Doctor</Text>
                  <Text className="result-details__side-detail__data" size="small">{resultDetails.order.orderer}</Text>
                </div>
                <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Reported Date</Text>
                    <Text className="result-details__side-detail__data" size="small">{moment(resultDetails.createdAt).format('DD/MM/YYYY')}</Text>
                </div>
               
                <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Status</Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        className={classNames('result-details__status-dot', {
                          'result-details__status-dot--yellow': resultDetails.status !== 'final',
                          'result-details__status-dot--green': resultDetails.status === 'final',
                        })}
                      />
                      <Text className="result-details__side-detail__data" size="small" uppercase>{resultDetails.status}</Text>
                    </div>
                </div>


              <Text size="big" dark className="mb-2" color="grey" style={{ borderTop: 'solid 1px #eeeeee', paddingTop: '10px' }}>Patient</Text>
                <div className="result-details__side-detail" style={{ cursor: 'pointer' }} onClick={() => history.push(`/patients/${resultDetails.patient.id}`)}>
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Name</Text>
                    <Text className="result-details__side-detail__data" size="small" color="link">{resultDetails.patient.fullName}</Text>
                </div>
                {resultDetails.patient.ssn && (
                  <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>NRIC/FIN</Text>
                    <Text className="result-details__side-detail__data" size="small">{resultDetails.patient.ssn}</Text>
                  </div>
                )}
                {resultDetails.patient.passNo && (
                  <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Passport Number</Text>
                    <Text className="result-details__side-detail__data" size="small">{resultDetails.patient.passNo}</Text>
                  </div>
                )}
                {resultDetails.patient.gender && (
                  <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Gender</Text>
                    <Text className="result-details__side-detail__data" size="small" uppercase>{resultDetails.patient.gender}</Text>
                  </div>
                )}
                {resultDetails.patient.dateOfBirth && (
                  <div className="result-details__side-detail">
                    <Text className="result-details__side-detail__header" color="grey" size="tiny" dark>Date of Birth</Text>
                    <Text className="result-details__side-detail__data" size="small" uppercase>{moment(resultDetails.patient.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                  </div>
                )}
                
            </div>
          )}
          <div className="result-details__pdf-wrapper">
            <PDFViewer file={result.file} name={result.name} title={result.title}/>
          </div>
        </div>
      ))})
      
    tabPanes.push({ menuItem: 'Result Details', render: () => <div className="result-details__details-wrapper">
    {isLoading ?
      <div className="result-details__details-wrapper__loader-wrapper">
        <Loader active className="result-details__details-wrapper__loader-wrapper__loader" size="small" />
      </div>
      : (
          <>
            <Text size="big" dark className="mt-2" color="grey" style={{ marginBottom: '12px' }}>Order</Text>
            <div className="result-details__details-wrapper__row">
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Number</Text>
                  <Text className="result-details__detail__data">{resultDetails.order.id}</Text>
                </div>
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Date</Text>
                  <Text className="result-details__detail__data">{moment(resultDetails.order.date).format('DD/MM/YYYY')}</Text>
                </div>
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Tests/Profiles</Text>
                  <Text className="result-details__detail__data">{resultDetails.testProfile}</Text>
                </div>
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Ordering Doctor</Text>
                  <Text className="result-details__detail__data">{resultDetails.order.orderer}</Text>
                </div>
                <div className="result-details__detail">
                    <Text className="result-details__detail__header" color="grey" size="small" dark>Reported Date</Text>
                    <Text className="result-details__detail__data">{moment(resultDetails.createdAt).format('DD/MM/YYYY')}</Text>
                </div>
                <div className="result-details__detail">
                    <Text className="result-details__detail__header" color="grey" size="small" dark>Status</Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        className={classNames('result-details__status-dot', {
                          'result-details__status-dot--yellow': resultDetails.status !== 'final',
                          'result-details__status-dot--green': resultDetails.status === 'final',
                        })}
                      />
                      <Text className="result-details__detail__data" uppercase>{resultDetails.status}</Text>
                    </div>
                </div>
            </div>

            <Text size="big" dark color="grey" style={{ borderTop: 'solid 1px #eeeeee', paddingTop: '20px', marginBottom: '12px', marginTop: '10px' }}>Patient</Text>
            <div className="result-details__details-wrapper__row">
              <div className="result-details__detail" style={{ cursor: 'pointer' }} onClick={() => history.push(`/patients/${resultDetails.patient.id}`)}>
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Name</Text>
                  <Text className="result-details__detail__data" color="link">{resultDetails.patient.fullName}</Text>
              </div>
              {resultDetails.patient.ssn && (
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>NRIC/FIN</Text>
                  <Text className="result-details__detail__data">{resultDetails.patient.ssn}</Text>
                </div>
              )}
              {resultDetails.patient.passNo && (
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Passport Number</Text>
                  <Text className="result-details__detail__data">{resultDetails.patient.passNo}</Text>
                </div>
              )}
              {resultDetails.patient.gender && (
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Gender</Text>
                  <Text className="result-details__detail__data" uppercase>{resultDetails.patient.gender}</Text>
                </div>
              )}
              {resultDetails.patient.dateOfBirth && (
                <div className="result-details__detail">
                  <Text className="result-details__detail__header" color="grey" size="small" dark>Date of Birth</Text>
                  <Text className="result-details__detail__data" uppercase>{moment(resultDetails.patient.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                </div>
              )}
            </div>
              {/* {clientInfo.type === 'LAB' ? (
                  <div className="result-details__detail">
                        <Text className="result-details__detail__header" color="grey" size="small" dark>Clinic</Text>
                        <Text className="result-details__detail__data">{resultDetails.clinic.name}</Text>
                    </div>
              ) : (
                  <div className="result-details__detail">
                      <Text className="result-details__detail__header" color="grey" size="small" dark>Lab</Text>
                      <Text className="result-details__detail__data">{resultDetails.lab.name}</Text>
                  </div>
              )} */}
          </>
      )}
    </div> });  
    setPanes(tabPanes)
  }

  return (
    <div className="result-details">
      <div className="result-details__header">
        <Button
            icon
            onClick={() => history.goBack()}
            color="blue"
            size="small"
            className="mr-4"
        >
            <Icon name="arrowLeft" color="white" fontSize="1.3em" />
        </Button>
      </div>

      {tests.length > 0 && (
        <div className="result-details__test-table">
          <Text type="header" className="ml-2">Tests</Text>
          <Table>
            <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Updated At</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderTableData()}</Table.Body>
          </Table>
        </div>
      )}
      <div className="result-details__tab-wrapper">
        <Tab className="result-details__tab-wrapper__tab" menu={{ borderless: true, attached: false, tabular: false, vertical: true }} panes={panes} />
      </div>
    </div>
  );
};

export default withRouter(ResultDetails);
