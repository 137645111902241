import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '../components';
import colors from '../styles/colors';

const CheckBox = props => {
  return (
    <CheckBoxWrapper
      isLabel={!!props.label}
      className={props.className}
      style={props.style}
    >
      {!!props.label && (
        <CheckBoxLabel>
          <Text
            type="label"
            style={{ paddingTop: '3px !important' }}
            className={props.labelClassName}
          >
            {props.label}
          </Text>
        </CheckBoxLabel>
      )}
      <CheckBoxInput type="checkbox" onClick={() => props.onClick()} />
      <Checkmark checked={props.checked} />
    </CheckBoxWrapper>
  );
};

CheckBox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

CheckBox.defaultProps = {
  onClick: () => {},
  checked: false,
  label: '',
};

const CheckBoxWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: ${props => (props.isLabel ? '35px' : '0')};
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  width: 25px;
`;

const CheckBoxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckBoxLabel = styled.div`
  position: relative;
  line-height: 26px;
  width: 150px;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 2px;
  border: solid 1px ${colors.GREY};

  &:hover {
    background-color: #eee;
  }

  &:after {
    content: '';
    position: absolute;
    display: ${props => (props.checked ? 'inline-block' : 'none')};
    bottom: 5px;
    left: 7px;
    transform: rotate(45deg);
    height: 16px;
    width: 8px;
    border-bottom: solid 4px ${colors.HIGHLIGHT_BLUE};
    border-right: solid 4px ${colors.HIGHLIGHT_BLUE};
  }
`;

export default CheckBox;
