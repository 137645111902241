import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, withRouter} from 'react-router-dom';

import {
  Input,
  Button,
  Modal,
  ErrorToast,
  SuccessToast,
} from '../../components';
import history from '../../history';
import authService from '../../api/auth';
import ClientService from '../../api/clients';
import PasswordReset from '../passwordReset/PasswordReset';
import ActivateUser from '../activateUser/ActivateUser';
import { createUserStorage } from '../../auth/authentication';
import backgroundGraphic from '../../assets/graphics/e-lab-graphic.svg';

import { setUser, setClientInformation } from '../../redux/generalState.actions';

import './login.scss';

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isModalToggled, setIsModalToggled] = useState('');
  const [isResetting, setIsResetting] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    console.log("props.location?", props.location)
    if(props.location?.state?.email) setEmail(props.location.state.email)
  }, [])

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      const rootId = localStorage.getItem('ROOT_ID');
      await authService.onForgotPassword(resetEmail, rootId);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsModalToggled(false);
      setResetEmail('');
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }

    setIsResetting(false);
  };

  const fetchClientDetails = async (clientId) => {
    try {
      const { data } = await ClientService.getClient(clientId);
      dispatch(setClientInformation(data));
    } catch (error) {
      console.log('fetchClientDetails', error);
    }
  };


  const login = async () => {
    setIsLoggingIn(true);
    try {
      const rootId = localStorage.getItem('ROOT_ID');
      const { data } = await authService.onSignInUser({ password, username: email, labId: rootId });
      dispatch(setUser(data.user));
      createUserStorage(data);
      await fetchClientDetails(data.user.clientId);
      history.push('/results');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      login();
    }
  };

  return (
    <div className="login">
      {window.location.hostname.split('.')[0] !== 'elab' && <img className="login__bckg" src={backgroundGraphic} alt="bkcg"/>}

      <div className="login__card">
          {clientBranding.logo && (
            <img className="login__card__logo" alt="logo" src={clientBranding.logo} />
          )}
          <div className="login__card__content">
            <Switch>
              <Route
                path="/password-reset"
                exact
                component={() => (
                  <PasswordReset clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/user-activate"
                exact
                component={() => (
                  <ActivateUser clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/activate"
                exact
                component={() => (
                  <ActivateUser clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/"
                exact
                render={() => (
                  <>
                    {/* <div className="login__card__header">eLab Portal</div> */}
                    <Input
                      border
                      label='Email'
                      className="login__card__input"
                      placeholder="mail@domain.com"
                      size="fullWidth"
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      autoComplete="username"
                    />
                    <Input
                      border
                      label='Password'
                      className="login__card__input"
                      placeholder="**********"
                      size="fullWidth"
                      type="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                      autoComplete="password"
                    />
                    <Button
                      className="login__card__button"
                      onClick={() => login()}
                      bold
                      color='blue'
                      isLoading={isLoggingIn}
                    >
                      Login
                    </Button>
                    <div className="login__card__forgot-wrapper">
                      <Button
                        className="login__card__forgot"
                        size="medium"
                        onClick={() => setIsModalToggled(true)}
                        inverted
                        color="white"
                      >
                        <span
                          className="login__card__forgot__text"
                          style={{
                            color:
                              clientBranding?.color || '#53b1f3',
                          }}
                        >
                          Forgot password?
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              />
            </Switch>
          </div>
      </div>
      {/* <div className="login__image">
        <img
          src={background + '.jpg'}
          alt="background"
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && (
          <img
            src={background + '-mini.jpg'}
            alt="background"
            className="login__image--mini"
          />
        )}
      </div> */}
      {isModalToggled && (
        <Modal
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="You forgot your password? Enter your email below to recieve a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color='blue'
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          }
        >
          <Input
            border
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
