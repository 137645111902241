import React, { memo } from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Text } from '../components';
import colors from '../styles/colors';

// TODO: fixed width of buttons, as they should be fluid in width, not fixed.

const Button = memo(props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  const fontSize = props.size === 'xsmall' ? '11px' : '15px';
  const lineHeight = props.size === 'xsmall' ? '13px' : '17px';

  const renderContent = () => {
    if (props.isLoading)
      return (
        <LoaderWrapper color={props.color} circular={props.circular}>
          <Loader inverted style={{ display: 'flex' }} size="small" />
        </LoaderWrapper>
      );

    if (props.icon)
      return (
        <div
          //TODO: fix this
          style={{
            paddingTop: '2px',
            paddingLeft: '1px',
            paddingBottom: '1px',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'none'
          }}
        >
          {props.children}
        </div>
      );

    return (
      <Text
        style={{ fontSize: fontSize, lineHeight: lineHeight }}
        color={getTextColor(props, clientBranding)}
        type="button"
        bold={props.bold}
        uppercase={props.uppercase}
        size="small"
      >
        {props.children}
      </Text>
    );
  };

  return (
    <ButtonWrapper
      hasHighlight={props.hasHighlight}
      style={props.style}
      selectedColor={props.selectedColor}
      size={props.size}
      color={props.color}
      circular={props.circular}
      inverted={props.inverted}
      selected={props.selected}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      className={props.className}
      backgroundColor={props.backgroundColor}
      clientBranding={clientBranding}
    >
      {renderContent()}
    </ButtonWrapper>
  );
});

Button.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  circular: PropTypes.bool,
  inverted: PropTypes.bool,
  selected: PropTypes.bool,
  hasHighlight: PropTypes.bool,
};

Button.defaultProps = {
  size: 'medium',
  color: 'black',
  isLoading: false,
  circular: false,
  inverted: false,
  selected: false,
  hasHighlight: true,
};

const getTextColor = (props, clientBranding) => {
  if (props.selected && props.selectedColor && props.inverted)
    return getColor(props.selectedColor, clientBranding?.color);
  if (props.inverted) return getColor(props.color, clientBranding?.color);
  else return 'white';
};

const getColor = (color, clientColor) => {
  if (color === 'grey') return colors.DARK_GREY;
  if (color === 'black') return 'black';
  if (color === 'white') return 'white';
  if (color === 'blue') return clientColor || colors.BLUE;
  if (color === 'lightBlue') return colors.BLUE;
  if (color === 'darkBlue') return colors.DARK_BLUE;
  if (color === 'yellow') return colors.YELLOW;
  if (color === 'green') return '#13a060';
  if (color === 'red') return colors.RED;
  if (color === 'purple') return '#5d53f3';
  if (color === 'none') return 'none';

  return color;
};

const getSize = props => {
  if (props.circular) {
    if (props.size === 'small')
      return {
        height: '30px',
        width: '30px',
        minWidth: '30px',
        minHeight: '30px',
      }; // random value
    if (props.size === 'medium')
      return {
        height: '40px',
        width: '40px',
        minWidth: '40px',
        minHeight: '40px',
      }; // don't change this one
    if (props.size === 'large')
      return {
        height: '50px',
        width: '50px',
        minWidth: '50px',
        minHeight: '50px',
      }; // random value
  } else {
    if (props.size === 'xsmall')
      return { minHeight: '25px', height: '25px', width: '70px' }; // random value
    if (props.size === 'small')
      return { minHeight: '30px', height: '30px', width: '100px' }; // random value
    if (props.size === 'medium')
      return { minHeight: '40px', height: '40px', width: '150px' }; // don't change this one
    if (props.size === 'large')
      return { minHeight: '46px', height: '46px', width: '250px' }; // random value
    if (props.size === 'wide')
      return { height: '40px', width: '400px', 'font-weight': 'bold' }; // don't change this one
    if (props.size === 'fluid')
      return { width: 'initial', padding: '7px 20px' }; // don't change this one
    if (props.size === 'fullWidth')
      return { width: '100%', minHeight: '42px', height: '42px' }; // don't change this one
  }
};

const buttonStyle = props => {
  let style = { background: getColor(props.color, props.clientBranding?.color) };
  if (props.inverted && !props.selected) {
    style = {
      ...style,
      'box-shadow': `0 0 0 1px ${getColor(props.color, props.clientBranding?.color)} inset !important`,
      background: props.backgroundColor || 'white',
    };
  }

  if (props.inverted && props.selected && props.selectedColor) {
    style = {
      ...style,
      'box-shadow': `0 0 0 1px ${getColor(
        props.selectedColor,
        props.clientBranding?.color
      )} inset !important`,
      background: props.backgroundColor || 'white',
    };
  }

  if (props.disabled) {
    style = {
      ...style,
      opacity: '0.8 !important',
      'pointer-events': 'none !important',
    };
  }

  return style;
};

const buttonType = props => {
  if (props.circular) {
    return {
      'border-radius': '30px !important',
    };
  }

  if (props.type === 'pill') {
    return {
      'border-radius': '30px !important',
    };
  }

  return {
    'border-radius': '10px !important',
  };
};

const backgroundColor = (color, hasHighlight) => {
  if (!hasHighlight) return 'initial';
  return getColor(color, hasHighlight);
};

const hoverColor = hasHighlight => {
  if (!hasHighlight) return;
  return 'white';
};

const ButtonWrapper = styled.button`
    ${props => buttonStyle(props)}
    ${props => buttonType(props)}
    ${props => getSize(props)};
    border: none;
    cursor: pointer;
    position: relative;
    border-radius: 10px;

    div {
      transition: color, background-color .2s ease-in-out;
    }

    /* &:hover {
      background-color: ${props =>
        backgroundColor(props.color, props.hasHighlight)};
    } */

    /* &:hover div {
      color: ${props => hoverColor(props.hasHighlight)};;
    } */

    &:focus {
      outline: none;
    }

    &:active {
      opacity: ${props => (props.disabled ? 1 : 0.8)};
    }
`;

const LoaderWrapper = styled.div`
  ${props => buttonType(props)}
  background: 'transparent';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default Button;
