import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { HeaderBar, SideBar } from '../../features';
import { Button, Input, Modal, SuccessToast, ErrorToast } from '../../components';
import UserService from '../../api/user';

import './pageWrapper.scss';

const PageWrapper = props => {
  const contentRef = useRef();
  const [isModalToggled, setIsModalToggled] = useState(false);
  const [isResetting, setIsResetting] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const userEmail = useSelector(state => state.general.user.email);

  // This is used to scroll to the top after every route change
  useEffect(() => {
    const unlisten = props.history.listen(() => {
      if(contentRef && contentRef.current) contentRef.current.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setResetEmail(userEmail);
  }, [userEmail]);

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      const rootId = localStorage.getItem('ROOT_ID');
      await UserService.onForgotPassword(resetEmail, rootId);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsModalToggled(false);
      setResetEmail('');
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }
    setIsResetting(false);
  };

  return (
    <>
      <div className="page-wrapper">
        <HeaderBar auth={props.auth} setIsModalToggled={setIsModalToggled} />
        <SideBar />
        <div className="page-wrapper__content" ref={contentRef} auth={props.auth}>
          {props.children}
        </div>
      </div >
      {isModalToggled && (
        <Modal
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="Enter your email below to recieve a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color={
                clientInformation && clientInformation.color
                  ? clientInformation.color
                  : 'blue'
              }
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          }
        >
          <Input
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};


export default withRouter(PageWrapper);
