import React from 'react';

import { Text } from '../../../../components';

import './clientBranding.scss';

const ClientBranding = ({ brandColor, logo }) => {
  return (
    <div className="client-branding">
      <div className="client-branding__header" />
      <div className="client-branding__content">
        <div className="client-branding__content__data">
          <div className="client-branding__data-wrapper">
            <Text dark size="small">
              Primary color
            </Text>
            <div className="client-branding__data-wrapper__data">
              <div
                className="client-branding__data-wrapper__brand-color"
                style={{ background: brandColor }}
              />
              <Text
                size="small"
                className="client-branding__data-wrapper__brand-color__color"
              >
                {brandColor}
              </Text>
            </div>
          </div>
          <div className="client-branding__data-wrapper">
            <Text dark size="small">
              Logo
            </Text>
            <div className="client-branding__data-wrapper__data">
              <img
                src={logo}
                alt="logo"
                className="client-branding__data-wrapper__logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientBranding;
