import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Text } from '../components';

import AddButton from './AddButton';

const FileUpload = props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  return (
    <Wrapper
      style={props.style}
      className={props.className}
      invalid={props.invalid}
      isIcon={props.icon}
      clientBranding={clientBranding}
    >
      {props.icon && <AddButton label={props.label} />}
      {!props.icon && (
        <Text size="small" bold color="#5b53f3">
          {props.label}
        </Text>
      )}
      <Input
        onChange={props.onSelect}
        accept={props.accept}
        type="file"
        disabled={props.disabled}
      />
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          This field is required
        </Text>
      )}
    </Wrapper>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func,
  style: PropTypes.object,
};

FileUpload.defaultProps = {
  onSelect: () => {},
  icon: true,
};

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px !important;
  padding: 5px;
  position: relative;
  border: solid 1px ${props => props.clientBranding?.color || '#5b53f3'};
  display: inline-flex;
  height: ${props => (!props.isIcon ? '35px' : 'initial')};
  width: ${props => (!props.isIcon ? '100%' : 'initial')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    opacity: 0.9;
  }
`;

const Input = styled.input`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 99999999;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export default FileUpload;
