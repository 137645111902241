import React from 'react';
import styled from 'styled-components';
import { Search as S } from 'semantic-ui-react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Text } from '../components';
import colors from '../styles/colors';

const Search = props => {
  const clientBranding = useSelector(
    state => state.general.clientBranding
  );

  const getSearchMethod = () => {
    if (props.isFilter) return props.onSearchChange;
    return debounce(props.onSearchChange, 500, {
      leading: true,
    });
  };
  return (
    <>
      {props.label && (
        <Text bold style={{ marginBottom: '10px' }}>
          {props.label}
        </Text>
      )}
      <Searchbar dropdown={props.dropdown} size={props.size} icon={props.icon} clientBranding={clientBranding}>
        <S
          className={'search-bar ' + props.className}
          results={props.results}
          loading={props.isLoading}
          onSearchChange={getSearchMethod()}
          onClick={e => e.stopPropagation()}
          onResultSelect={props.onResultSelect}
          placeholder="Search"
          icon="sds"
          input={{ icon: props.icon ? 'search' : 'none', iconPosition: 'left' }}
          value={props.value}
        />
      </Searchbar>
    </>
  );
};

Search.propTypes = {
  isLoading: PropTypes.bool,
  onResultSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  open: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.bool,
  results: PropTypes.array,
};

Search.defaultProps = {
  onResultSelect: () => {},
  onSearchChange: () => {},
  size: 'medium',
  open: false,
  isLoading: false,
  icon: true,
  dropdown: true,
};

const getSize = size => {
  if (size === 'small')
    return `{ width: 200px; .ui.input>input { border: solid 1px ${colors.GREY} } }`;
  if (size === 'medium')
    return `{ width: 300px; .ui.input>input { border: solid 1px ${colors.GREY} } }`;
  if (size === 'large')
    return `{ width: 400px; .ui.input>input { border: solid 1px ${colors.GREY} } }`;
  if (size === 'fullWidth')
    return `{ width: 100%; .ui.input>input { border: solid 1px ${colors.GREY} } }`;
};

const getIconStyle = props => {
  const style = {};

  if (!props.icon) style['padding-left'] = 0 + '!important';

  return style;
};

const Searchbar = styled.div`
  .search-bar {
    .ui.input > input {
      border: none;
      font-size: 17px;
      padding-left: 10px !important;
      height: 40px !important;
      color: ${colors.DARK_GREY};
      border-radius: 10px !important;
      background: white !important;

      &:focus {
        border: solid 1px ${props => props.clientBranding?.color || colors.PURPLE};
      }
    }

    ${props => getSize(props.size)}

    .input,
  .prompt {
      width: 100%;
    }

    .ui.icon.input > i.icon {
      font-size: 18px;
      width: 30px;
      margin-left: 5px !important;
    }

    .ui[class*='left icon'].input > input {
      padding-left: 40px !important;
      ${props => getIconStyle(props)}
      background: transparent;
    }

    &:focus {
      .ui.input > input {
        border-bottom: solid 1px #85b7d9;
        background: transparent;
      }
    }

    .results.transition.visible {
      display: ${props => (props.dropdown ? 'visible' : 'none')} !important;
    }
  }
`;

export default Search;
